import React from 'react'

const Renewal = () => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="26"
    height="26"
    viewBox="0 0 1000 1000"
  >
    <g>
      <path d="M268.2,909.5c37.5,0,290.1,75.8,322.2,80.4c0.6,0.1,1.4,0.1,2.2,0.1c41.1,0,302.7-116.2,345.4-132.4c56.6-21.6,44.3-92.8-7.6-92.8c-7.7,0-16.4,1.6-25.8,5.2c-72.5,27.6-202.2,78.6-231.7,88.2c-2.7,0.9-6.6,1.3-11.5,1.3c-48.7,0-195.2-40.4-223.6-52.8c-39-17-21-67.5,16.8-67.5c3.1,0,6.3,0.3,9.6,1.1c44.2,9.5,144,29.8,207.7,44.7c5.4,1.3,10.6,1.9,15.5,1.9c53.2,0,76.7-68.3,26.3-82.1c-54.8-15-282-89.7-327.3-89.7c-0.3,0-0.5,0-0.8,0c-43.4,0.8-305.5,111.5-347.5,132.3c-40,19.6,17.1,219.1,74,219.1c2.9,0,5.9-0.5,8.8-1.6c59.6-22.6,117.4-51.1,144.8-55.1C266.5,909.5,267.3,909.5,268.2,909.5z" />
      <path d="M659.4,619.6c0.1,0,0.3,0.1,0.4,0.1c31,9.5,57.8,17.7,71.4,21.4c23.3,6.4,42.5,19,56.1,36.6c50.7-62.6,81.1-142.1,81.1-228.7c0-10.4-8.4-18.7-18.8-18.7h-95.8c-10.4,0-18.8,8.3-18.8,18.7C734.9,516.6,705.8,577.4,659.4,619.6z" />
      <path d="M187.1,612.5c43.6-17.4,80.7-31.4,110.4-41.6c3.2-1.1,6.3-2.2,9.3-3.2c-20.9-34.7-32.9-75.2-32.9-118.6c0-127.1,103.4-230.5,230.5-230.5c13.4,0,26.9,1.1,40.2,3.6v65.2c0,7.6,4.6,14.4,11.5,17.3c7.2,2.9,15.1,1.2,20.6-4.1l128.9-129.5c7.3-7.4,7.3-19.1,0-26.5L576.7,15.5c-5.4-5.4-13.4-6.9-20.6-4.1c-6.9,2.9-11.5,9.8-11.5,17.3v58.8c-13.4-1.5-26.9-2.3-40.2-2.3c-200.6,0-363.8,163.3-363.8,363.9c0,59.7,14.5,116.2,40.1,165.9C182.8,614.2,184.9,613.4,187.1,612.5z" />
    </g>
  </svg>
)

export default Renewal
