import { merge } from 'ramda'

import {
  SET_WEBVIEW,
  LOGOUT,
  SET_HAS_NO_MEMBER_OR_METER,
  SWITCH_METER_REQUEST,
  SWITCH_METER_SUCCESS,
  CLOSE_SWITCH_MODAL,
  IN_APP_MESSAGES_SUCCESS,
  SHOW_MESSAGES_INBOX,
  SET_IMPERSONATED,
} from './constants'

import * as storage from 'utils/storage'

export const initialState = {
  webView: null,
  logout: false,
  impersonatingUserId: storage.getImpersonatingUserId(),
  switchMeter: null,
  hasNoMemberOrMeter: false,
  inAppMessages: [],
  showMessagesInbox: false,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_WEBVIEW:
      return merge(state, {
        webView: action.payload,
      })

    case LOGOUT: {
      return { ...state, logout: true }
    }

    case SET_HAS_NO_MEMBER_OR_METER: {
      return {
        ...state,
        hasNoMemberOrMeter: action.payload,
      }
    }

    case SET_IMPERSONATED: {
      storage.setImpersonatingUserId(action.payload)
      return {
        ...state,
        impersonatingUserId: action.payload,
      }
    }

    case SWITCH_METER_REQUEST: {
      const { nextMeterNum } = action.payload
      return {
        ...state,
        switchMeter: { isSwitching: true, nextMeterNum },
      }
    }

    case SWITCH_METER_SUCCESS: {
      return { ...state, switchMeter: { isSwitching: false } }
    }

    case CLOSE_SWITCH_MODAL: {
      return { ...state, switchMeter: null }
    }

    case IN_APP_MESSAGES_SUCCESS: {
      return {
        ...state,
        ...action.payload,
      }
    }

    case SHOW_MESSAGES_INBOX: {
      return {
        ...state,
        showMessagesInbox: !state.showMessagesInbox,
      }
    }

    default:
      return state
  }
}

export default reducer
