export const METER_LOOKUP_REQUEST = 'signup/METER_LOOKUP_REQUEST'
export const METER_LOOKUP_FAILURE = 'signup/METER_LOOKUP_FAILURE'
export const METER_LOOKUP_SUCCESS = 'signup/METER_LOOKUP_SUCCESS'

export const ENROLL_REQUEST = 'signup/ENROLL_REQUEST'
export const ENROLL_FAILURE = 'signup/ENROLL_FAILURE'
export const ENROLL_SUCCESS = 'signup/ENROLL_SUCCESS'

export const SUBMIT_SIGN_UP_FORM = 'signup/SUBMIT_SIGN_UP_FORM'
export const SUBMIT_SERVICE_ADDRESS_FORM = 'signup/SUBMIT_SERVICE_ADDRESS_FORM'
export const SUBMIT_SELECT_ADDRESS_FORM = 'signup/SUBMIT_SELECT_ADDRESS_FORM'
export const SUBMIT_SERVICE_INFO_FORM = 'signup/SUBMIT_SERVICE_INFO_FORM'
export const SUBMIT_PERSONAL_INFO_FORM = 'signup/SUBMIT_PERSONAL_INFO_FORM'
export const SUBMIT_PAYMENT_FORM = 'signup/SUBMIT_PAYMENT_FORM'

export const SAVE_SERVICE_ADDRESS_VALUES = 'signup/SAVE_SERVICE_ADDRESS_VALUES'
export const SAVE_SELECT_ADDRESS_VALUES = 'signup/SAVE_SELECT_ADDRESS_VALUES'
export const SAVE_SERVICE_INFO_VALUES = 'signup/SAVE_SERVICE_INFO_VALUES'
export const SAVE_PERSONAL_INFO_VALUES = 'signup/SAVE_PERSONAL_INFO_VALUES'
export const SAVE_START_DATE = 'signup/SAVE_START_DATE'

export const ADD_CARD_FAILURE = 'signup/ADD_CARD_FAILURE'
export const ADD_CARD_SUCCESS = 'signup/ADD_CARD_SUCCESS'

export const ADD_FUNDS_FAILURE = 'signup/ADD_FUNDS_FAILURE'
export const ADD_FUNDS_SUCCESS = 'signup/ADD_FUNDS_SUCCESS'

export const ADD_METER_FAILURE = 'signup/ADD_METER_FAILURE'
export const ADD_METER_SUCCESS = 'signup/ADD_METER_SUCCESS'

export const POPULATE = 'signup/POPULATE'

export const SET_SHOULD_REDIRECT_TO_PAYMENT =
  'signup/SET_SHOULD_REDIRECT_TO_PAYMENT'
