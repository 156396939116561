import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { compose, withStateHandlers, lifecycle } from 'recompose'
import withOutsideClick from 'react-onclickoutside'
import { Trans } from '@lingui/macro'

import * as mixpanel from 'utils/mixpanel'
import { changeCurrentMember as changeCurrentMemberAction } from 'modules/members/actions'
import { selectCurrentMeterName } from 'modules/members/selectors'
import { TextSemi } from 'components/text'
import { color } from 'styles/variables'

import MeterItem from './MeterItem'
import { Wrapper, Button, List, ListItem, ListButton, Section } from './styled'

const Dropdown = ({
  name,
  members,
  meterName,
  isOpen,
  changeCurrentMember,
  toggle,
  logout,
}) => {
  const [membersList, setMembersList] = useState([])
  const isMultiMeter = membersList.length > 1

  useEffect(() => {
    setMembersList(members.filter(x => !x.archive))
  }, [members])

  if (isMultiMeter) {
    return (
      <Wrapper>
        <Button
          isOpen={isOpen}
          onClick={toggle}
          data-testid="switchMeterDropdownButton"
        >
          <TextSemi color={color.secondary}>{meterName}</TextSemi>
        </Button>
        {isOpen && (
          <List width={300} data-testid="switchMeterList">
            <TextSemi p="10px 15px">
              <Trans>Welcome, {name}</Trans>
            </TextSemi>
            <Section>
              {membersList.map((member, index) => {
                const meterName = `Meter ${index + 1}`

                return (
                  <MeterItem
                    key={member.memberID}
                    dataTestId={meterName}
                    meterName={<Trans>Meter {index + 1}</Trans>}
                    member={member}
                    onClick={() => {
                      if (!member.isSelected) {
                        toggle()
                        changeCurrentMember({
                          member,
                          nextMeterNum: index + 1,
                        })
                      }
                    }}
                  />
                )
              })}
            </Section>
            <Section data-testid="meterSummaryButton">
              <Link
                to="/account/meter-summary"
                onClick={() => {
                  mixpanel.clickMeterSummary('dropdown')
                  toggle()
                }}
              >
                <ListButton>
                  <Trans>Meter Summary</Trans>
                </ListButton>
              </Link>
            </Section>
            <Section pb={0} data-testid="logoutButton">
              <ListButton
                onClick={() => {
                  toggle()
                  logout()
                }}
              >
                <Trans>Logout</Trans>
              </ListButton>
            </Section>
          </List>
        )}
      </Wrapper>
    )
  }

  return (
    <Wrapper>
      <Button data-testid="headerDropDown" isOpen={isOpen} onClick={toggle}>
        <TextSemi p="10px 15px">
          {name ? <Trans>Welcome, {name}</Trans> : <Trans>Welcome</Trans>}
        </TextSemi>
      </Button>
      {isOpen && (
        <List>
          <ListItem>
            <ListButton
              onClick={() => {
                toggle()
                logout()
              }}
              fontSize="1.4rem"
              p="7px 10px"
            >
              <Trans>Logout</Trans>
            </ListButton>
          </ListItem>
        </List>
      )}
    </Wrapper>
  )
}
Dropdown.propTypes = {
  name: PropTypes.string.isRequired,
  members: PropTypes.array.isRequired,
  meterName: PropTypes.node,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  changeCurrentMember: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
}

const mapStateToProps = createStructuredSelector({
  meterName: selectCurrentMeterName,
})

export default compose(
  connect(
    mapStateToProps,
    { changeCurrentMember: changeCurrentMemberAction }
  ),
  withStateHandlers(
    { isOpen: false },
    { toggle: ({ isOpen }) => () => ({ isOpen: !isOpen }) }
  ),
  withOutsideClick,
  lifecycle({
    handleClickOutside() {
      if (this.props.isOpen) {
        this.props.toggle()
      }
    },
  })
)(Dropdown)
