export const USAGE_QUERY_PARAMS_LOADED = 'USAGE_QUERY_PARAMS_LOADED'
export const USAGE_PAGE_LOADED = 'USAGE_PAGE_LOADED'
export const USAGE_METERID_SELECTED = 'USAGE_METERID_SELECTED'
export const DATE_CONTEXT_UPDATED = 'DATE_CONTEXT_UPDATED'
export const DATE_CONTEXT_UPDATE_TRIGGERED = 'DATE_CONTEXT_UPDATED_TRIGGERED'
export const SEARCH_UPDATE_TRIGGERED = 'SEARCH_UPDATE_TRIGGERED'
export const SEARCH_CONTEXT_UPDATED = 'SEARCH_CONTEXT_UPDATED'
export const GET_USAGE_REQUEST = 'GET_USAGE_REQUEST'
export const GET_USAGE_SUCCESS = 'GET_USAGE_SUCCESS'
export const GET_USAGE_FAILURE = 'GET_USAGE_FAILURE'
export const GRAPH_CONTEXT_UPDATED = 'GRAPH_CONTEXT_UPDATED'
export const USAGE_DATA_IN_STATE = 'USAGE_DATA_IN_STATE'
export const DRILLDOWN_DATE_SELECTED = 'DRILLDOWN_DATE_SELECTED'
export const GRAPH_BAR_SELECTED = 'GRAPH_BAR_SELECTED'
// export const SWITCH_METER_REQUEST = 'SWITCH_METER_REQUEST'
// export const SWITCH_METER_SUCCESS = 'SWITCH_METER_SUCCESS'
export const SWITCH_USAGE_METER = 'SWITCH_USAGE_METER'
export const CLEAR_USAGE_DATA = 'CLEAR_USAGE_DATA'
export const GET_NET_METER_BALANCE_SUCCESS = 'GET_NET_METER_BALANCE_SUCCESS'
export const GET_NET_METER_BALANCE_FAILURE = 'GET_NET_METER_BALANCE_SUCCESS'
export const SET_VIEWING_GENERATION = 'SET_VIEWING_GENERATION'
export const UPDATE_VIEWING_GENERATION = 'UPDATE_VIEWING_GENERATION'
