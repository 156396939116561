import { takeLatest, call, fork, put, all, select } from 'redux-saga/effects'
import { delay } from 'redux-saga'
import {
  getRenewalOfferFailure,
  getRenewalOfferSuccess,
  submitRenewalOfferFormSuccess,
  submitRenewalOfferFormFailure,
  clearRenewalEnrollmentData,
} from './actions'
import { selectCurrentMemberID } from 'modules/members/selectors'
import { selectRenewalOffer } from 'pages/Renewal/selectors'
import {
  GET_RENEWAL_OFFER_REQUEST,
  SELECT_OFFER,
  SUBMIT_RENEWAL_OFFER_FORM,
} from './constants'
import * as API from 'api'
import { push } from 'react-router-redux'
import { hideRenewalPage } from 'pages/Renewal/actions'
function* enrollRenewalOffer({ setters }) {
  // handle multiple tabs
  const item = localStorage.getItem('renewal_offer_submit_date')
  if (item) {
    // redirect if renewal token already exists
    let now = new Date().getTime()
    const msBetweenDates = Math.abs(item - now)
    const hoursBetweenDates = msBetweenDates / (60 * 60 * 1000)
    if (hoursBetweenDates < 24) {
      yield put(hideRenewalPage(true))
      yield put(clearRenewalEnrollmentData())
      return yield put(push('/'))
    }
  }

  const storeMemberID = yield select(selectCurrentMemberID)
  const renewal = yield select(selectRenewalOffer)
  renewal.enrollment.formData.memberID = storeMemberID

  renewal.enrollment.formData.product = {
    ...renewal.enrollment.formData.product,
    term_months: renewal.enrollment.formData.product.term_months.toString(),
    max_deposit: renewal.enrollment.formData.product.max_deposit.toString(),
  }
  const body = {
    ...renewal.enrollment,
  }
  try {
    const resp = yield call(API.enrollRenewalOffer, body)
    yield put(submitRenewalOfferFormSuccess({ ...resp }))
    if (setters.toggleSuccessModal) {
      yield call(setters.toggleSuccessModal)
      // set timestamp for submit date
      localStorage.setItem('renewal_offer_submit_date', new Date().getTime())
      yield put(hideRenewalPage(true))
      yield delay(3000)
      // clear enrollment form data so user can't load payload on signup page
      yield put(clearRenewalEnrollmentData())
      yield put(push('/'))
    }
  } catch (err) {
    yield put(
      submitRenewalOfferFormFailure(
        'Something went wrong, please try again later!'
      )
    )
    if (setters.toggleErrorModal) {
      yield call(setters.toggleErrorModal)
    }
    if (setters.setSubmitting) {
      yield call(setters.setSubmitting, false)
    }
  }
}

export function* renewalSaga() {
  const storeMemberID = yield select(selectCurrentMemberID)
  yield call(getCurrentRenewalOffers, {
    memberID: storeMemberID,
  })
}

export function* getCurrentRenewalOffers({ memberID }) {
  const body = {
    memberID,
  }
  try {
    const resp = yield call(API.getRenewalOffers, body)
    yield put(getRenewalOfferSuccess({ ...resp, offers: body.renewal_offers }))
  } catch (err) {
    yield put(
      getRenewalOfferFailure('Something went wrong, please try again later!')
    )
  }
}

export function* selectOfferSaga() {
  yield put(push('/renewal/signup'))
}

export default function* defaultSaga() {
  yield all([
    fork(renewalSaga),
    takeLatest(GET_RENEWAL_OFFER_REQUEST, getCurrentRenewalOffers),
    takeLatest(SELECT_OFFER, selectOfferSaga),
    takeLatest(SUBMIT_RENEWAL_OFFER_FORM, enrollRenewalOffer),
  ])
}
