import { merge } from 'ramda'
import { DateTime } from 'luxon'

import {
  GET_STATEMENT_REQUEST,
  GET_STATEMENT_SUCCESS,
  SET_STATEMENT_DATE,
} from './constants'

export const initialState = {
  selectedDate: DateTime.local().toFormat('yyyy-MM'),
  selectedStatementDate: DateTime.local().toFormat('yyyy-MM'),
  statements: [],
  isLoading: false,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_STATEMENT_REQUEST: {
      return merge(state, { isLoading: true })
    }

    case GET_STATEMENT_SUCCESS: {
      return merge(state, { statements: action.payload, isLoading: false })
    }

    case SET_STATEMENT_DATE: {
      return merge(state, {
        selectedStatementDate: action.date,
      })
    }
    default:
      return state
  }
}

export default reducer
