import { LOCATION_CHANGE } from 'react-router-redux'

import * as mixpanel from './mixpanel'
import * as piwik from './piwikPixel'

import {
  LOGOUT,
  RESET,
  SWITCH_METER_SUCCESS,
  EXPERIMENT_DECIDED,
} from 'modules/app/constants'
import {
  LOGIN_SUCCESS,
  GET_USER_SUCCESS,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAILURE,
} from 'modules/user/constants'
import {
  SET_CURRENT_MEMBER,
  UPDATE_MEMBER_SUCCESS,
  UPDATE_MEMBER_FAILURE,
  CREATE_MEMBER_SUCCESS,
  CREATE_MEMBER_FAILURE,
  CREATE_GUEST_SUCCESS,
  ADD_MEMBER_SUCCESS,
} from 'modules/members/constants'
import { ENROLL_SUCCESS } from 'pages/SignUp/constants'
import { MM_ENROLL_SUCCESS } from 'pages/AddMeter/constants'
import { SET_TIME_INTERVAL } from 'pages/ElectricityUsage/constants'
import {
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,
  ADD_FUNDS_SUCCESS,
  ADD_FUNDS_FAILURE,
} from 'pages/Account/constants'

import { selectIsAuthenticated, selectGGStatus } from 'modules/user/selectors'
import { selectLocale } from 'modules/language/selectors'
import { getPromoBannerVariation } from 'utils/storage'

const track = ({ getState, action }) => {
  switch (action.type) {
    case LOCATION_CHANGE: {
      const state = getState()
      const lang = selectLocale(state)
      const { pathname, state: routerState = {} } = action.payload

      if (
        pathname === '/sign-up/not-available' ||
        pathname === '/add-meter/not-available'
      ) {
        mixpanel.unsupportedMeter(routerState.error)
      }

      if (pathname.startsWith('/sign-up')) {
        const promoBannerVariation = getPromoBannerVariation()
        piwik.iconMediaPageView(action.payload.pathname)
        piwik.iconMediaLead(action.payload.pathname)
        return mixpanel.pageView(action.payload, lang, promoBannerVariation)
      }

      piwik.iconMediaPageView(action.payload.pathname)
      piwik.iconMediaLead(action.payload.pathname)
      return mixpanel.pageView(action.payload, lang)
    }

    case SET_TIME_INTERVAL:
      return mixpanel.pageView({ pathname: `/usage-${action.interval}` })

    case LOGIN_SUCCESS: {
      piwik.setIconMediaUser(action.payload.UserID)
      mixpanel.identify(action.payload.userID)
      return mixpanel.signIn({ ggStatus: action.payload.gg_status })
    }

    case LOGOUT:
      return mixpanel.reset()

    case RESET: {
      if (selectIsAuthenticated(getState())) {
        return mixpanel.reset()
      }
      return null
    }

    case CREATE_USER_SUCCESS: {
      mixpanel.alias(action.payload.userID)
      piwik.setIconMediaUser(action.payload.userID)
      piwik.iconMediaLead(action.payload.pathname)
      return mixpanel.createUser(action.payload)
    }
    case CREATE_USER_FAILURE:
      return mixpanel.error('createUser', action.payload)

    case CREATE_MEMBER_SUCCESS: {
      return mixpanel.createMember(action.member)
    }
    case CREATE_MEMBER_FAILURE:
      return mixpanel.error('createMember', action.payload)

    case GET_USER_SUCCESS:
      return mixpanel.userGet(action.payload)

    case SET_CURRENT_MEMBER:
      return mixpanel.memberSet(action.payload)

    case UPDATE_USER_SUCCESS:
      return mixpanel.userUpdate(action.payload)
    case UPDATE_USER_FAILURE:
      return mixpanel.error('userUpdate', action.error.message)

    case UPDATE_MEMBER_SUCCESS: {
      if (action.updateType === 'updateGuest') {
        return null
      }
      if (action.updateType === 'updateRecharge') {
        return mixpanel.updateRecharge(action.payload)
      }
      return mixpanel.memberUpdate(action.payload)
    }
    case UPDATE_MEMBER_FAILURE:
      return mixpanel.error('memberUpdate', action.error.message)

    case MM_ENROLL_SUCCESS:
    case ENROLL_SUCCESS: {
      const state = getState()
      const ggStatus = selectGGStatus(state)
      if (ggStatus === 'active') {
        piwik.iconMediaGuestAcquisition()
      }
      if (ggStatus === 'unavailable' || ggStatus === 'converted') {
        piwik.iconMediaAcquisition(action.type)
      }
      return mixpanel.signupComplete(action.mixpanelProps)
    }

    case ADD_FUNDS_SUCCESS:
      return mixpanel.addFunds(action.mixpanelProps)
    case ADD_FUNDS_FAILURE:
      return mixpanel.addFunds(action.mixpanelProps)

    case ADD_MEMBER_SUCCESS:
      return mixpanel.createMemberMM(action.member)

    case CREATE_GUEST_SUCCESS: {
      const state = getState()
      const ggStatus = selectGGStatus(state)
      piwik.iconMediaGuestUser(action.guest.memberID)
      return mixpanel.createGuest({ memberID: action.guest.memberID, ggStatus })
    }

    case SWITCH_METER_SUCCESS: {
      const { meterName, numberOfMeters, griddyRateClass } = action.payload
      return mixpanel.switchMeter({
        meterName,
        griddyRateClass,
        numberOfMeters,
      })
    }

    case EXPERIMENT_DECIDED: {
      const { experiment } = action.payload
      return mixpanel.experimentDecision(experiment)
    }

    default:
      return null
  }
}

export default ({ token }) => {
  mixpanel.init(token)

  return ({ getState }) => next => action => {
    try {
      track({ getState, action })
    } catch (error) {
      console.error(error)
    }

    return next(action)
  }
}
