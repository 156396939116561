export const premiseTypes = {
  UNKNOWN: 'premise_unknown',
  RESIDENTIAL: 'premise_residential',
  SMALL_NON_RESIDENTIAL: 'premise_small_non_residential',
  MEDIUM_NON_RESIDENTIAL: 'premise_medium_non_residential',
  LARGE_NON_RESIDENTIAL: 'premise_large_non_residential',
}

export const meterTypes = {
  OUT_OF_AREA: 'OUT_OF_AREA',
  NO_METER: 'NO_METER',
  LARGE_COMMERCIAL: 'LARGE_COMMERCIAL',
  BLANK_TDSP_AMS: 'BLANK_TDSP_AMS',
  SWITCH_HOLD: 'SWITCH_HOLD',
}
