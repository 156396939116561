import React from 'react'

const Logout = () => (
  <svg width="26" height="26" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h26v26H0z" />
      <path
        d="M20.5857864,12 L17.2928932,8.70710678 C16.9023689,8.31658249 16.9023689,7.68341751 17.2928932,7.29289322 C17.6834175,6.90236893 18.3165825,6.90236893 18.7071068,7.29289322 L23.7071068,12.2928932 C24.0976311,12.6834175 24.0976311,13.3165825 23.7071068,13.7071068 L18.7071068,18.7071068 C18.3165825,19.0976311 17.6834175,19.0976311 17.2928932,18.7071068 C16.9023689,18.3165825 16.9023689,17.6834175 17.2928932,17.2928932 L20.5857864,14 L8,14 C7.44771525,14 7,13.5522847 7,13 C7,12.4477153 7.44771525,12 8,12 L20.5857864,12 Z M4,22 L15,22 C15.5522847,22 16,22.4477153 16,23 C16,23.5522847 15.5522847,24 15,24 L3,24 C2.44771525,24 2,23.5522847 2,23 L2,3 C2,2.44771525 2.44771525,2 3,2 L15,2 C15.5522847,2 16,2.44771525 16,3 C16,3.55228475 15.5522847,4 15,4 L4,4 L4,22 Z"
        id="Combined-Shape"
        fill="#7D8D9E"
        fillRule="nonzero"
      />
    </g>
  </svg>
)

export default Logout
