import styled from 'styled-components'
import { NavLink } from 'react-router-dom'

import mq from 'styles/mq'
import { color } from 'styles/variables'

export const Aside = styled.aside`
  background: white;
  flex: 0;
  overflow: auto;
  padding-top: 20px;
  transition: flex-basis 0.5s;

  ${mq.tablet`
    border-right: 1px solid ${color.secondary};
    flex: 0 0 80px;
  `};

  ${mq.desktop`
    flex: 0 0 260px;
  `};
`

export const StyledLink = styled(NavLink)`
  position: relative;
  display: flex;
  align-items: center;
  height: 80px;
  padding-left: 25px;
  color: #7d8d9e;
  font-size: 1.6rem;
  font-weight: 500;
  text-decoration: none;
  transition: all 0.3s ease;

  &.active,
  &:hover {
    color: ${color.primary};
    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 4px;
      background: ${color.primary};
    }
  }

  svg {
    flex-shrink: 0;
  }
`

export const Label = styled.span`
  display: none;
  margin-left: 20px;
  line-height: 14px;
  white-space: nowrap;
  ${mq.desktop`
    display: block;
  `};
`
