import logo from 'assets/images/logos/juice.png'
import banner from 'assets/images/logos/plusBannerLogoJuice.png'

const isDev = !window.location.href.includes('app.gogriddy')

const base = '#333e6b'
const primary = '#F37021'
const secondary = '#0F3762'
const blue = '#0F3762'
const gray = '#d5dee5'
const grayMedium = '#7D8D9E'
const grey = '#999999'
const green = '#7CA51B'
const greenPromo = '#8FAB36'
const error = '#dd2c00'
const neutral1 = '#022135'
const neutral2 = '#7D8D9E'
const neutral3 = '#9DB1C6'
const neutral4 = '#D5DEE5'
const neutral5 = '#F2F3F6'
const white = '#fff'
const footerColor = '#2a3048' // 001f2e
const darkerPrimary = '#e48957'

export const color = {
  background: '#f2f3f6',
  base,
  primary,
  secondary,
  blue,
  gray,
  grayMedium,
  grey,
  green,
  greenPromo,
  error,
  gradient: white,
  link: primary,
  neutral1,
  neutral2,
  neutral3,
  neutral4,
  neutral5,
  white,
  footerColor,
  darkerPrimary,
  buttonColor: '#fff',
  buttonColorSecondary: '#fff',
  buttonBorder: primary,
  buttonBackground: '#fff',
  costColors: {
    first: '#f38762',
    second: '#f7935c',
    third: '#6b497e',
    fourth: '#333e6b',
  },
  usageGraph: {
    isPending: '#F37021',
    isNotPending: '#F37021',
    gradientStart: '#A64D17',
    gradientEnd: '#F37021',
    base: '#F37021',
  },
  ratePanel: secondary,
  rateText: primary,
  offerDetails: neutral3,
  offerShortDescription: neutral2,
  renewalBanner: {
    text: 'white',
    background: primary,
  },
  plusBanner: {
    background: primary,
    bannerTitle: 'The Savings Hub',
    bannerUrl: 'https://www.juicetx.com/other-services/',
    bannerIcon: banner,
    bannerTextEn:
      'Take your benefits of being a JUICE Customer beyond Energy! Introducing The Savings Hub. An opportunity for you to save on your other monthly bills like cable, phone, internet without changing your current service. Chances are, your monthly bills have increased and you’re being overcharged. Need more info? Want to get started?',
    bannerTextEs:
      '¡Lleva tus beneficios de ser un cliente de JUICE más allá de la energía! Presentamos The Savings Hub. Una oportunidad para que ahorres en tus otras facturas mensuales como cable, teléfono, Internet sin cambiar tu servicio actual. Lo más probable es que sus facturas mensuales hayan aumentado y le estén cobrando de más. ¿Necesitas más información? ¿Quieres empezar?',
  },
}

export const companyInfo = {
  portalName: 'JUICE',
  fullName: 'JUICE',
  shortName: 'JUICE',
  email: 'care@juicetx.com',
  phone: '(888) 832-3596',
  text_number: '',
  hoursOfOperation: '(Mon - Fri 7am - 7pm CST)',
  signUpLink: 'https://enroll.juicetx.com',
  defaultTimeZone: 'US/Central',
  homepageLink: 'https://www.juicetx.com',
  mixpanelToken: '',
  renewalBaseUrl: 'https://enroll.juicetx.com/renewal',
  allowMakePaymentEnterNewCard: true,
}

export const logoConfig = {
  localPath: 'assets/images/logos/juice.png',
  url: '',
  width: 'auto',
  height: '120px',
  useLocal: true,
  imgType: 'png',
  logo,
  wrapper: {
    padding: 10,
    backgroundColor: 'transparent',
  },
}

const baseFamily = "'Montserrat', sans-serif"
const customFont = "'Montserrat', sans-serif"

export const font = {
  extraBold: '800',
  semiBold: '600',
  medium: '500',
  normal: '400',
  baseFamily,
  customFont,
  customFamily: `${customFont}, ${baseFamily}`,
}

export const fontFace = `
@font-face {
    font-family: ${font.customFont};
    src: url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
  }
`

export const routes = {
  homePagePath: '/account',
  wholesale: false,
  devices: false,
}

export const features = {
  ADD_FUNDS_PAST_DUE_BALANCE: true,
  ADD_FUNDS_CURRENT_BALANCE: true,
  ADD_FUNDS_LAST_BILL_AMOUNT: false,
  ADD_FUNDS_BALANCE_DUE: false,
  ACCOUNT_BALANCE_DUE: false,
  ACCOUNT_PAST_DUE_BALANCE: true,
  ACCOUNT_CURRENT_BALANCE: true,
  CURRENT_BALANCE_BANNER: false,
  REFER_A_FRIEND: false,
  RECHARGE_AMOUNT: false,
  ADD_METER: false,
  REACT_APP_ZENDESK_CHAT_ENABLE:
    process.env.REACT_APP_ZENDESK_CHAT_ENABLE === 'true' ? true : false,
  RENEWAL_BANNER: true,
  PLUS_BANNER: true,
  SHOW_SIGN_UP_LINK: false,
  DISABLE_BILLING_TOGGLES: false,
}

export const url = {
  SIGN_UP: isDev
    ? 'https://config.d3fbicgal063f7.amplifyapp.com'
    : 'https://enroll.juice.com',
  ZENDESK_SECTIONS:
    'api/v2/help_center/en-us/categories/9387528580759-Juice/sections.json',
}

export const graphs = {
  usage_kwh: true,
  price_ckwh: false,
  cost_dollars: false,
}
