export const CREATE_MEMBER_FAILURE = 'members/CREATE_MEMBER_FAILURE'
export const CREATE_MEMBER_SUCCESS = 'members/CREATE_MEMBER_SUCCESS'

export const GET_MEMBERS_REQUEST = 'members/GET_MEMBERS_REQUEST'
export const GET_MEMBERS_FAILURE = 'members/GET_MEMBERS_FAILURE'
export const GET_MEMBERS_SUCCESS = 'members/GET_MEMBERS_SUCCESS'

export const GET_ENROLLMENT_REQUEST = 'members/GET_ENROLLMENT_REQUEST'
export const GET_ENROLLMENT_SUCCESS = 'members/GET_ENROLLMENT_SUCCESS'
export const GET_ENROLLMENT_FAILURE = 'members/GET_ENROLLMENT_FAILURE'

export const SET_CURRENT_MEMBER = 'members/SET_CURRENT_MEMBER'
export const CHANGE_CURRENT_MEMBER = 'members/CHANGE_CURRENT_MEMBER'

export const UPDATE_MEMBER_REQUEST = 'members/UPDATE_MEMBER_REQUEST'
export const UPDATE_MEMBER_SUCCESS = 'members/UPDATE_MEMBER_SUCCESS'
export const UPDATE_MEMBER_FAILURE = 'members/UPDATE_MEMBER_FAILURE'

export const ADD_MEMBER_FAILURE = 'members/ADD_MEMBER_FAILURE'
export const ADD_MEMBER_SUCCESS = 'members/ADD_MEMBER_SUCCESS'

export const GET_METER_REQUEST = 'members/GET_METER_REQUEST'
export const GET_METER_FAILURE = 'members/GET_METER_FAILURE'
export const GET_METER_SUCCESS = 'members/GET_METER_SUCCESS'

export const GET_GUESTS_REQUEST = 'members/GET_GUESTS_REQUEST'
export const GET_GUESTS_FAILURE = 'members/GET_GUESTS_FAILURE'
export const GET_GUESTS_SUCCESS = 'members/GET_GUESTS_SUCCESS'

export const CREATE_GUEST_SUCCESS = 'members/CREATE_GUEST_SUCCESS'
