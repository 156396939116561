import { css } from 'styled-components'

export const breakpoints = {
  smallPhone: 340,
  phone: 500,
  tablet: 768,
  desktop: 1080,
}

const mq = Object.keys(breakpoints).reduce((accumulator, breakpoint) => {
  const emSize = breakpoints[breakpoint] / 16
  accumulator[breakpoint] = (...args) => css`
    @media (min-width: ${emSize}em) {
      ${css(...args)};
    }
  `
  return accumulator
}, {})

export default mq
