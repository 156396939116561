import React from 'react'

export default () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <path
        d="M7.414 6l4.293 4.293a1 1 0 0 1-1.414 1.414L6 7.414l-4.293 4.293a1 1 0 1 1-1.414-1.414L4.586 6 .293 1.707A1 1 0 1 1 1.707.293L6 4.586 10.293.293a1 1 0 0 1 1.414 1.414L7.414 6z"
        id="close-button"
      />
    </defs>
    <use fill="currentColor" xlinkHref="#close-button" fillRule="evenodd" />
  </svg>
)
