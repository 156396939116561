import React from 'react'
import PropTypes from 'prop-types'
import { HamburgerButton } from './styled'

const Hamburger = ({ onClick, isOpen }) => (
  <HamburgerButton onClick={onClick} isOpen={isOpen}>
    <span />
    <span />
    <span />
    <span />
  </HamburgerButton>
)

Hamburger.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default Hamburger
