import { createSelector } from 'reselect'

const selectWholesale = state => state.wholesale

export const selectNow = createSelector(
  selectWholesale,
  wholesale => wholesale.now
)

export const selectWeather = createSelector(
  selectWholesale,
  wholesale => wholesale.weather
)

export const selectForecast = createSelector(
  selectWholesale,
  wholesale => wholesale.forecast
)

export const selectRefreshAt = createSelector(
  selectWholesale,
  wholesale => wholesale.refreshAt
)

export const selectLoading = createSelector(
  selectWholesale,
  wholesale => wholesale.loading
)
