import { LOCATION_CHANGE } from 'react-router-redux'
import { merge } from 'ramda'

import globalReducers from 'modules/reducers'
import experimentReducer from 'modules/experiment/reducer'
import signUpReducer from 'pages/SignUp/reducer'
import dashboardReducer from 'pages/Dashboard/reducer'
import wholesaleReducer from 'pages/Wholesale/reducer'
import usageReducer from 'pages/ElectricityUsage/reducer'
import usageV2Reducer from 'pages/Usage/reducer'
import statementReducer from 'pages/Statement/reducer'
import accountReducer from 'pages/Account/reducer'
import supportReducer from 'pages/Support/reducer'
import addMeterReducer from 'pages/AddMeter/reducer'
import priceLockReducer from 'pages/PriceLock/reducer'
import renewalReducer from 'pages/Renewal/reducer'
import devicesReducer from 'pages/Devices/reducer'

const routeInitialState = {
  location: null,
}

const routeReducer = (state = routeInitialState, action) => {
  switch (action.type) {
    /* istanbul ignore next */
    case LOCATION_CHANGE:
      return merge(state, {
        location: action.payload,
      })
    default:
      return state
  }
}

export default {
  route: routeReducer,
  signUp: signUpReducer,
  dashboard: dashboardReducer,
  wholesale: wholesaleReducer,
  priceLock: priceLockReducer,
  usage: usageReducer,
  usageV2: usageV2Reducer,
  statement: statementReducer,
  account: accountReducer,
  support: supportReducer,
  addMeter: addMeterReducer,
  experiment: experimentReducer,
  renewal: renewalReducer,
  devices: devicesReducer,
  ...globalReducers,
}
