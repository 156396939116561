import { push } from 'react-router-redux'
import isEmpty from 'lodash.isempty'
import { DateTime } from 'luxon'

import * as API from 'api'
import { premiseTypes, meterTypes } from 'api/constants'
import * as squatch from 'utils/squatch'
import { formatDate } from 'utils/dateUtils'

import { updateUserSuccess } from 'modules/user/actions'
import { makeSelectUserID } from 'modules/user/selectors'
import { createMemberSuccess } from 'modules/members/actions'
import { enrollSuccess } from 'pages/SignUp/actions'
import { resetPromo } from 'modules/promos/actions'
import * as storage from 'utils/storage'

const isBusinessLocation = meter =>
  meter.premise === premiseTypes.SMALL_NON_RESIDENTIAL ||
  meter.premise === premiseTypes.MEDIUM_NON_RESIDENTIAL

const initialState = {
  meterID: '',
  selectedAddress: { line1: '', line2: '', postal_code: '' },
  meterList: [],
  selectedMeter: {},
  serviceInformation: {},
  personalInformation: {
    first_name: '',
    last_name: '',
    birthday: '',
    phone: '',
  },
  enrollingMember: {},
  shouldRedirectToPayment: false,
}

const enroll = {
  name: 'enroll',
  state: initialState,
  reducers: {
    initForm(state, initialData) {
      return {
        ...initialState,
        ...initialData,
      }
    },
    setSelectedAddress(state, selectedAddress) {
      return {
        ...state,
        selectedAddress,
      }
    },
    getMeterListSuccess(state, meterList) {
      return {
        ...state,
        meterList,
      }
    },
    setMeterID(state, meterID) {
      return {
        ...state,
        meterID,
      }
    },
    setSelectedMeter(state, selectedMeter) {
      return {
        ...state,
        selectedMeter,
      }
    },
    setServiceInformation(state, serviceInformation) {
      return {
        ...state,
        serviceInformation,
      }
    },
    setPersonalInformation(state, personalInformation) {
      return {
        ...state,
        personalInformation,
      }
    },
    setEnrollingMember(state, member) {
      return {
        ...state,
        enrollingMember: member,
      }
    },
    setShouldRedirectToPayment(state, shouldRedirectToPayment) {
      return {
        ...state,
        shouldRedirectToPayment,
      }
    },
  },
  effects: dispatch => ({
    async getMeterList({ line1, line2, postal_code }) {
      dispatch.enroll.setSelectedAddress({ line1, line2, postal_code })
      const { meters } = await API.meterLookup({ line1, line2, postal_code })
      if (isEmpty(meters)) {
        dispatch(
          push({
            pathname: '/enroll/not-available',
            state: {
              error: meterTypes.OUT_OF_AREA,
              address: { line1, line2, postal_code },
            },
          })
        )
        return
      }
      dispatch.enroll.getMeterListSuccess(meters)
      dispatch(push('/enroll/select-address'))
    },
    selectAddress({ esi }, rootState) {
      const {
        enroll: { meterList, shouldRedirectToPayment },
      } = rootState
      const selectedMeter = meterList.find(m => m.esi === esi)
      dispatch.enroll.setMeterID(esi)
      dispatch.enroll.setSelectedMeter(selectedMeter)

      if (!selectedMeter.supported_meter) {
        const state = { error: meterTypes.NO_METER }

        if (selectedMeter.metered === 'n') {
          state.error = meterTypes.NO_METER
        } else if (
          selectedMeter.premise === premiseTypes.LARGE_NON_RESIDENTIAL
        ) {
          state.error = meterTypes.LARGE_COMMERCIAL
        } else if (selectedMeter.tdsp_ams_indicator === '') {
          state.error = meterTypes.BLANK_TDSP_AMS
        } else if (selectedMeter.switch_hold) {
          state.error = meterTypes.SWITCH_HOLD
        }

        return dispatch(
          push({
            pathname: '/enroll/not-available',
            state,
          })
        )
      }

      if (shouldRedirectToPayment) {
        dispatch.enroll.setShouldRedirectToPayment(false)

        if (isBusinessLocation(selectedMeter)) {
          return dispatch(push('/enroll/business'))
        }
        return dispatch(push('/enroll/payment'))
      }

      return dispatch(push('/enroll/service'))
    },
    submitServiceInformation(values, rootState) {
      const {
        enroll: { shouldRedirectToPayment },
      } = rootState

      const requestedStartDate =
        values.requestedStartDate &&
        DateTime.fromJSDate(values.requestedStartDate).toFormat('yyyy-LL-dd')

      dispatch.enroll.setServiceInformation({ ...values, requestedStartDate })

      if (shouldRedirectToPayment) {
        dispatch.enroll.setShouldRedirectToPayment(false)
        return dispatch(push('/enroll/payment'))
      }

      return dispatch(push('/enroll/personal'))
    },
    async submitPersonalInformation(values, rootState) {
      const {
        enroll: { meterID, meterList, enrollingMember },
      } = rootState
      dispatch.enroll.setPersonalInformation(values)
      const { first_name, last_name, birthday, phone } = values

      const userID = makeSelectUserID()(rootState)
      const language = storage.getLang()
      const userBody = { userID, phone, language }
      await API.updateUser(userBody)
      const user = await API.getUser({ userID })
      dispatch(updateUserSuccess(user))

      const selectedMeter = meterList.find(m => m.esi === meterID)
      const address = Object.keys(selectedMeter.address).reduce((acc, key) => {
        if (key === 'line1') {
          acc.street_address = selectedMeter.address[key]
        } else {
          acc[key] = selectedMeter.address[key]
        }
        return acc
      }, {})

      const memberBody = {
        address,
        first_name,
        last_name,
        birthday: DateTime.fromFormat(birthday, 'MM/dd/yyyy').toFormat(
          'yyyy-LL-dd'
        ),
      }

      if (enrollingMember.memberID) {
        await API.updateMember({
          memberID: enrollingMember.memberID,
          ...memberBody,
        })

        const updatedMember = {
          ...enrollingMember,
          ...memberBody,
          birthday: DateTime.fromFormat(birthday, 'MM/dd/yyyy', {
            zone: 'utc',
          }),
        }
        dispatch.enroll.setEnrollingMember(updatedMember)
      } else {
        const newMember = await API.createMember({
          userID,
          member_agreement: true,
          ...memberBody,
        })

        dispatch.enroll.setEnrollingMember(newMember)
      }

      if (isBusinessLocation(selectedMeter)) {
        return dispatch(push('/enroll/business'))
      }

      return dispatch(push('/enroll/payment'))
    },
    async submitPaymentForm(stripeToken, rootState) {
      const {
        user: { userID, email },
        enroll: {
          meterID,
          enrollingMember,
          serviceInformation,
          personalInformation,
          selectedMeter,
        },
      } = rootState
      const { memberID } = enrollingMember
      const {
        enrollMode,
        requestedStartDate,
        firstAvailableDate,
      } = serviceInformation
      const { first_name, last_name } = personalInformation

      await API.updateMember({
        memberID,
        first_name,
        last_name,
      })
      await API.enroll({ memberID, emailAddress: email })

      const card = await API.addCard({
        memberID,
        stripeToken: stripeToken.id,
        is_default: true,
      })

      await API.addFunds({
        memberID,
        initial_payment: true,
        new_payment_amount: '49',
        payment_methodID: card.cardID,
        hashID: String(Date.now()),
      })

      const addMeterBody = {
        memberID,
        enrollMode,
        meterID,
      }
      if (!firstAvailableDate) {
        addMeterBody.requestedStartDate = requestedStartDate
      }
      await API.addMeter(addMeterBody)

      squatch.upsert(userID, email, true)

      const account = await API.getAccount({ memberID })
      const balance = {
        current: account.account_balance,
        pending: account.account_pending_balance,
        estTopOffDate: account.est_top_off_date,
      }

      const res = await API.getMeter({ memberID })
      let meter = null
      if (res.meters.length) {
        meter = res.meters[0]
      }

      const member = {
        ...enrollingMember,
        balance,
        meter,
      }

      dispatch(createMemberSuccess(member))
      dispatch(push('/enroll/thank-you'))

      let requestType = 'move'
      if (enrollMode === 'switch') {
        requestType = 'switch'
        if (firstAvailableDate) {
          requestType = 'fasd'
        }
      }
      const mixpanelProps = {
        memberID,
        requestType,
        loadZone: selectedMeter.load_zone,
        meterCity: selectedMeter.address.city,
        meterZipcode: selectedMeter.address.postal_code,
        requestedStartDate: requestedStartDate
          ? formatDate(requestedStartDate)
          : null,
        polrRateClass: selectedMeter.polr_customer_class,
      }

      dispatch(enrollSuccess(mixpanelProps))
    },
    resetPromo() {
      dispatch(resetPromo())
    },
  }),
  selectors: (slice, createSelector) => ({
    meterID() {
      return slice(state => state.meterID)
    },
    selectedMeter() {
      return slice(state => state.selectedMeter)
    },
    selectedAddress() {
      return slice(state => ({
        ...initialState.selectedAddress,
        ...state.selectedAddress,
      }))
    },
    meterList() {
      return slice(state => state.meterList)
    },
    isResidential() {
      return createSelector(
        this.selectedMeter,
        selectedMeter => selectedMeter.griddy_rate_class === 'residential'
      )
    },
    isCommercial() {
      return createSelector(
        this.selectedMeter,
        selectedMeter => selectedMeter.griddy_rate_class === 'commercial'
      )
    },
    selectedMeterAddress() {
      return createSelector(
        this.selectedMeter,
        selectedMeter => selectedMeter.address
      )
    },
    serviceInformation() {
      return slice(state => state.serviceInformation)
    },
    requestedStartDate() {
      return createSelector(this.serviceInformation, serviceInformation => {
        if (!serviceInformation.requestedStartDate) {
          return null
        }
        const startDate = DateTime.fromISO(
          serviceInformation.requestedStartDate
        )
        if (startDate.isValid) {
          return startDate.toFormat('LL/dd/yyyy')
        }
        return null
      })
    },
    isMoveIn() {
      return createSelector(
        this.serviceInformation,
        serviceInformation => serviceInformation.enrollMode === 'movein'
      )
    },
    firstAvailableDate() {
      return createSelector(
        this.serviceInformation,
        serviceInformation => serviceInformation.firstAvailableDate
      )
    },
    personalInformation() {
      return slice(state => state.personalInformation)
    },
    firstName() {
      return createSelector(
        this.personalInformation,
        personalInformation => personalInformation.first_name
      )
    },
  }),
}

export default enroll
