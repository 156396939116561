import * as C from './constants'

export const loginRequest = payload => ({
  type: C.LOGIN_REQUEST,
  payload,
})

export const loginFailure = payload => ({
  type: C.LOGIN_FAILURE,
  payload,
})

export const loginSuccess = payload => ({
  type: C.LOGIN_SUCCESS,
  payload,
})

export const createUserRequest = () => ({
  type: C.CREATE_USER_REQUEST,
})

export const createUserFailure = payload => ({
  type: C.CREATE_USER_FAILURE,
  payload,
})

export const createUserSuccess = payload => ({
  type: C.CREATE_USER_SUCCESS,
  payload,
})

export const getUserRequest = () => ({
  type: C.GET_USER_REQUEST,
})
export const getUserSuccess = payload => ({
  type: C.GET_USER_SUCCESS,
  payload,
})
export const getUserFailure = payload => ({
  type: C.GET_USER_FAILURE,
  payload,
})

export const updateUserRequest = () => ({
  type: C.UPDATE_USER_REQUEST,
})
export const updateUserSuccess = payload => ({
  type: C.UPDATE_USER_SUCCESS,
  payload,
})
export const updateUserFailure = payload => ({
  type: C.UPDATE_USER_FAILURE,
  payload,
})

/**
 * FORMS SUBMIT
 */

export const submitLoginForm = (payload, setters) => ({
  type: C.SUBMIT_LOGIN_FORM,
  payload,
  setters,
})

export const saveFormEmail = payload => ({
  type: C.SAVE_FORM_EMAIL,
  payload,
})
