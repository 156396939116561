import React from 'react'
import PropTypes from 'prop-types'

import { color as colors } from 'styles/variables'
import { Wrapper, PageWrapper, Dot } from './styled'

const Loader = ({ color = colors.grey, size = 16 }) => (
  <Wrapper color={color} size={size}>
    <Dot />
    <Dot />
    <Dot />
  </Wrapper>
)

Loader.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
}

export default Loader

export const PageLoader = () => (
  <PageWrapper>
    <Loader />
  </PageWrapper>
)
