import React from 'react'
import PropTypes from 'prop-types'
import { Flex } from 'grid-styled'
import isEmpty from 'lodash.isempty'

import LocalizedDate from 'components/LocalizedDate'

import { Text, TextBold, TextSemi } from 'components/text'
import { Wrapper, StyledNewscard, CloseButton } from './styled'
import Close from 'assets/images/icons/Close'

const Newscard = ({ message, isOpen, onClose }) => {
  if (isEmpty(message)) {
    return null
  }
  const { customPayload: data } = message
  const date = new Date(message.createdAt).toISOString()

  return data ? (
    <Wrapper isOpen={isOpen}>
      <StyledNewscard isOpen={isOpen}>
        <Flex justifyContent="space-between" alignItems="flex-start" mb="16px">
          <img alt="news card icon" src={data.newsCardIcon} />
          <CloseButton
            data-testid="newscardCloseButton"
            onClick={() => onClose()}
          >
            <Close />
          </CloseButton>
        </Flex>
        <TextBold color="#fc2573" fontSize="36px" lineHeight="39px" mb="11px">
          {data.newsCardTitle}
        </TextBold>
        <Flex justifyContent="space-between" mb="6px">
          <TextSemi color="#7D8D9E" fontSize="11px">
            <LocalizedDate date={date} format="cccc, LLL. d, y" />
          </TextSemi>
          <TextSemi fontSize="11px">
            <LocalizedDate date={date} format="h:mma ZZZZ" />
          </TextSemi>
        </Flex>
        <Text fontSize="12px" lineHeight="20px">
          {data.newsCardBody}
        </Text>
      </StyledNewscard>
    </Wrapper>
  ) : null
}

Newscard.propTypes = {
  message: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default Newscard
