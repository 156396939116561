import { createSelector } from 'reselect'
import { pathOr } from 'ramda'
import { initialState } from './reducer'

/**
 * Direct selector to the registration state domain
 */
export const selectRenewalOffer = state => state.renewal
/**
 * Other specific selectors
 */

export const makeSelectError = () =>
  createSelector(selectRenewalOffer, substate =>
    pathOr(initialState.error, ['error'], substate)
  )

export const makeSelectIsLoading = () =>
  createSelector(selectRenewalOffer, substate =>
    pathOr(initialState.loading, ['loading'], substate)
  )

export const makeSelectOffers = () =>
  createSelector(selectRenewalOffer, substate => substate.offers)

export const makeSelectEnrollmentData = () =>
  createSelector(selectRenewalOffer, substate => {
    return {
      ...substate.enrollment,
    }
  })

export const makeSelectHideRenewalPage = () =>
  createSelector(selectRenewalOffer, substate =>
    pathOr(initialState.hideRenewalPage, ['hideRenewalPage'], substate)
  )
/**
 * Default selector used by registration
 */

export default () => createSelector(selectRenewalOffer, substate => substate)
