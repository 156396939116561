import { injectGlobal } from 'styled-components'
import { font, input, color, zIndex } from 'styles/variables'

injectGlobal`
  @font-face {
    font-family: ${font.customFont};
    src:
      url('/fonts/Gilroy-Regular.woff2') format('woff2'),
      url('/fonts/Gilroy-Regular.woff') format('woff');
    font-weight: ${font.normal};
    font-style: normal;
  }

  @font-face {
    font-family: ${font.customFont};
    src:
      url('/fonts/Gilroy-Medium.woff2') format('woff2'),
      url('/fonts/Gilroy-Medium.woff') format('woff');
    font-weight: ${font.medium};
    font-style: normal;
  }

  @font-face {
    font-family: ${font.customFont};
    src:
      url('/fonts/Gilroy-SemiBold.woff2') format('woff2'),
      url('/fonts/Gilroy-SemiBold.woff') format('woff');
    font-weight: ${font.semiBold};
    font-style: normal;
  }

  @font-face {
    font-family: ${font.customFont};
    src:
      url('/fonts/Gilroy-ExtraBold.woff2') format('woff2'),
      url('/fonts/Gilroy-ExtraBold.woff') format('woff');
    font-weight: ${font.extraBold};
    font-style: normal;
  }

  * {
    border: 0;
    margin: 0;
    outline: 0;
    padding: 0;
    text-decoration: none;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    appearance: none;
    -webkit-overflow-scrolling: touch;
  }

  html {
    font-size: 62.5%;
  }

  body {
    height: 0;
    overflow: visible;
    font-family: ${font.baseFamily};
    color: ${color.base};
    background-color: ${color.background};
    font-size: 1.4rem;
  }

  button {
    cursor: pointer;
    overflow-wrap: normal;
    background-color: transparent;
  }

  body.fontLoaded {
    font-family: ${font.customFamily};

    button {
      font-family: ${font.customFamily};
    }
  }

  body.no-scroll {
    overflow: hidden;
  }

  #app {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    flex-direction: column;
  }

  #modal {
    position: relative;
    z-index: ${zIndex.modal};
  }

  ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: ${input.placeholder};
  }

  ::-moz-placeholder { /* Firefox 19+ */
    color: ${input.placeholder};
  }

  :-ms-input-placeholder { /* IE 10+ */
    color: ${input.placeholder};
  }

  :-moz-placeholder { /* Firefox 18- */
    color: ${input.placeholder};
  }

  .blur-children {
    overflow: hidden;
    > :not(#blur-modal-container) {
      filter: blur(5px);
    }
  }
`
