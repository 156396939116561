import * as C from './constants'

export const setTimeInterval = interval => ({
  type: C.SET_TIME_INTERVAL,
  interval,
})

export const getHistoryRequest = () => ({
  type: C.GET_HISTORY_REQUEST,
})
export const getHistorySuccess = (meterID, payload) => ({
  type: C.GET_HISTORY_SUCCESS,
  meterID,
  payload,
})
export const getHistoryFailure = () => ({
  type: C.GET_HISTORY_FAILURE,
})
