import styled from 'styled-components'
import mq from 'styles/mq'
import { color } from 'styles/variables'

export const PlusSection = color.plusBanner
  ? styled.div`
  background: ${color.plusBanner.background};
  padding: 10px 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  ${mq.tablet`
    padding: 10px 20px;
    flex-direction: column;
  `};

  &:last-of-type {
    margin-bottom: 0;
  }
` : styled.div``;

export const PlusSectionDescription = color.plusBanner
  ? styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
`
  : styled.div``;

export const PlusDescription = styled.span`
  font-size: 1.6rem;
  text-align: justify;
  align-self: center;
  ${mq.tablet`
    display: flex;
  `};
`;