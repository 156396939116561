import { merge } from 'ramda'
import {
  GET_USER_FAILURE,
  GET_USER_SUCCESS,
  UPDATE_USER_SUCCESS,
  LOGIN_REQUEST,
  LOGIN_FAILURE,
  SAVE_FORM_EMAIL,
  CREATE_USER_REQUEST,
  CREATE_USER_SUCCESS,
} from './constants'

export const initialState = {
  first_name: '',
  last_name: '',
  email: '',
  error: '',
  userID: '',
  formEmail: '',
  billingAddress: {},
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_USER_REQUEST:
    case LOGIN_REQUEST: {
      // initialState here is important!
      return merge(initialState, {
        formEmail: state.formEmail,
      })
    }

    case SAVE_FORM_EMAIL: {
      return merge(state, {
        formEmail: action.payload,
      })
    }

    case CREATE_USER_SUCCESS:
    case GET_USER_SUCCESS: {
      const {
        userID,
        first_name,
        last_name,
        email,
        phone,
        gg_status,
        billing_address,
      } = action.payload

      let billingAddress

      try {
        billingAddress = JSON.parse(billing_address)
      } catch (err) {
        billingAddress = {}
      }

      return merge(state, {
        userID,
        first_name,
        last_name,
        email,
        phone,
        error: '',
        gg_status,
        formEmail: initialState.formEmail,
        billingAddress,
      })
    }

    case UPDATE_USER_SUCCESS: {
      return {
        ...state,
        ...action.payload,
      }
    }

    case LOGIN_FAILURE:
    case GET_USER_FAILURE:
      return merge(state, {
        error: action.payload,
      })

    default:
      return state
  }
}

export default reducer
