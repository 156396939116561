export const GET_PRICE_LOCK_RATE = 'pricelock/GET_PRICE_LOCK_RATE'
export const GET_PRICE_LOCK_RATE_SUCCESS =
  'pricelock/GET_PRICE_LOCK_RATE_SUCCESS'
export const GET_PRICE_LOCK_RATE_FAILURE =
  'pricelock/GET_PRICE_LOCK_RATE_FAILURE'

export const SET_PRICE_LOCK_REQUEST = 'pricelock/SET_PRICE_LOCK_REQUEST'
export const SET_PRICE_LOCK_REQUEST_SUCCESS =
  'pricelock/SET_PRICE_LOCK_REQUEST_SUCCESS'
export const SET_PRICE_LOCK_REQUEST_FAILURE =
  'pricelock/SET_PRICE_LOCK_REQUEST_FAILURE'

export const UPDATE_AUTO_SUMMER_LOCK = 'pricelock/UPDATE_AUTO_SUMMER_LOCK'
export const UPDATE_AUTO_SUMMER_LOCK_SUCCESS =
  'pricelock/UPDATE_AUTO_SUMMER_LOCK_SUCCESS'
export const UPDATE_AUTO_SUMMER_LOCK_FAILURE =
  'pricelock/UPDATE_AUTO_SUMMER_LOCK_FAILURE'

export const SET_IDS = 'pricelock/SET_IDS'
