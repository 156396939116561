import * as C from './constants'

export const createMemberFailure = () => ({
  type: C.CREATE_MEMBER_FAILURE,
})

export const createMemberSuccess = member => ({
  type: C.CREATE_MEMBER_SUCCESS,
  member,
})

export const getMembersRequest = () => ({
  type: C.GET_MEMBERS_REQUEST,
})
export const getMembersFailure = payload => ({
  type: C.GET_MEMBERS_FAILURE,
  payload,
})
export const getMembersSuccess = payload => ({
  type: C.GET_MEMBERS_SUCCESS,
  payload,
})

export const getEnrollmentRequest = () => ({
  type: C.GET_ENROLLMENT_REQUEST,
})
export const getEnrollmentFailure = payload => ({
  type: C.GET_ENROLLMENT_FAILURE,
  payload,
})
export const getEnrollmentSuccess = payload => ({
  type: C.GET_ENROLLMENT_SUCCESS,
  payload,
})

export const setCurrentMember = payload => ({
  type: C.SET_CURRENT_MEMBER,
  payload,
})

export const changeCurrentMember = payload => ({
  type: C.CHANGE_CURRENT_MEMBER,
  payload,
})

export const updateMemberRequest = () => ({
  type: C.UPDATE_MEMBER_REQUEST,
})
export const updateMemberSuccess = (payload, updateType) => ({
  type: C.UPDATE_MEMBER_SUCCESS,
  payload,
  updateType,
})
export const updateMemberFailure = error => ({
  type: C.UPDATE_MEMBER_FAILURE,
  error,
})

export const addMemberFailure = () => ({
  type: C.ADD_MEMBER_FAILURE,
})
export const addMemberSuccess = member => ({
  type: C.ADD_MEMBER_SUCCESS,
  member,
})

export const getMeterRequest = () => ({
  type: C.GET_METER_REQUEST,
})
export const getMeterFailure = payload => ({
  type: C.GET_METER_FAILURE,
  payload,
})
export const getMeterSuccess = payload => ({
  type: C.GET_METER_SUCCESS,
  payload,
})

export const getGuestsRequest = () => ({
  type: C.GET_GUESTS_REQUEST,
})
export const getGuestsFailure = payload => ({
  type: C.GET_GUESTS_FAILURE,
  payload,
})
export const getGuestsSuccess = payload => ({
  type: C.GET_GUESTS_SUCCESS,
  payload,
})

export const createGuestSuccess = guest => ({
  type: C.CREATE_GUEST_SUCCESS,
  guest,
})
