import React from 'react'
import PropTypes from 'prop-types'
import capitalize from 'utils/capitalize'

import { color } from 'styles/variables'
import { Text } from 'components/text'
import { MeterButton, Radio } from './styled'

const MeterItem = ({ dataTestId, meterName, member, onClick, hide }) => {
  const {
    isSelected,
    address,
    meter: {
      status: { status },
    },
  } = member
  const isDisconnected = status === 'disconnected'
  const textColor = isDisconnected ? color.secondary : undefined
  return (
    <MeterButton
      onClick={onClick}
      hide={hide}
      data-testid={`switchMeterButton:${dataTestId}`}
    >
      <Radio isChecked={isSelected} />
      <div>
        <Text large color={textColor}>
          {meterName}
        </Text>
        <Text small color={textColor}>
          {capitalize(address.street_address)}
        </Text>
      </div>
    </MeterButton>
  )
}

MeterItem.propTypes = {
  dataTestId: PropTypes.string.isRequired,
  meterName: PropTypes.node.isRequired,
  member: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  hide: PropTypes.bool,
}

export default MeterItem
