import logo from 'assets/images/logos/abundance.png'

const isDev = !window.location.href.includes('app.gogriddy')

const base = '#000'
const primary = '#2e8df9'
const secondary = '#003f8f'
const blue = '#0F3762'
const gray = '#d5dee5'
const grayMedium = '#7D8D9E'
const grey = '#999999'
const green = '#7CA51B'
const greenPromo = '#8FAB36'
const error = '#dd2c00'
const neutral1 = '#022135'
const neutral2 = '#7D8D9E'
const neutral3 = '#9DB1C6'
const neutral4 = '#D5DEE5'
const neutral5 = '#F2F3F6'
const white = '#fff'
const footerColor = '#2a3048'
const darkerPrimary = '#e48957'

export const color = {
  background: '#f2f3f6',
  base,
  primary,
  secondary,
  blue,
  gray,
  grayMedium,
  grey,
  green,
  greenPromo,
  error,
  gradient: white,
  link: primary,
  neutral1,
  neutral2,
  neutral3,
  neutral4,
  neutral5,
  white,
  footerColor,
  darkerPrimary,
  buttonColor: '#fff',
  buttonColorSecondary: '#fff',
  buttonBorder: primary,
  buttonBackground: '#fff',
  costColors: {
    first: '#f38762',
    second: '#f7935c',
    third: '#6b497e',
    fourth: base,
  },
  usageGraph: {
    isPending: primary,
    isNotPending: primary,
    gradientStart: secondary,
    gradientEnd: primary,
    base: primary,
  },
  ratePanel: secondary,
  rateText: primary,
  offerDetails: neutral3,
  offerShortDescription: neutral2,
  renewalBanner: {
    text: 'white',
    background: primary,
  },
}

export const companyInfo = {
  portalName: 'Abundance Energy',
  fullName: 'Abundance Energy',
  shortName: 'Abundance Energy',
  email: 'care@abundanceenergy.com',
  phone: '(833) 495-0127',
  text_number: '',
  hoursOfOperation: '(Mon - Fri 8am - 5pm CST)',
  signUpLink: 'https://join.abundanceenergy.com',
  defaultTimeZone: 'US/Central',
  homepageLink: 'https://www.abundanceenergy.com',
  mixpanelToken: '',
  renewalBaseUrl: 'https://join.abundanceenergy.com/renewal',
  paymentProvider: 'ICHECK',
  allowMakePaymentEnterNewCard: false,
  faviconLink: 'abundance.ico',
}

export const logoConfig = {
  localPath: 'assets/images/logos/abundance.png',
  url: '',
  width: 'auto',
  height: '60px',
  useLocal: true,
  imgType: 'png',
  logo,
  wrapper: {
    backgroundColor: 'transparent',
  },
}

const baseFamily = "'Montserrat', sans-serif"
const customFont = "'Montserrat', sans-serif"

export const font = {
  extraBold: '800',
  semiBold: '600',
  medium: '500',
  normal: '400',
  baseFamily,
  customFont,
  customFamily: `${customFont}, ${baseFamily}`,
}

export const fontFace = `
@font-face {
    font-family: ${font.customFont};
    src: url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
  }
`

export const routes = {
  homePagePath: '/account',
  wholesale: false,
  devices: false,
}

export const features = {
  ADD_FUNDS_PAST_DUE_BALANCE: false,
  ADD_FUNDS_CURRENT_BALANCE: false,
  ADD_FUNDS_LAST_BILL_AMOUNT: true,
  ADD_FUNDS_BALANCE_DUE: true,
  ACCOUNT_BALANCE_DUE: true,
  ACCOUNT_PAST_DUE_BALANCE: false,
  ACCOUNT_CURRENT_BALANCE: false,
  CURRENT_BALANCE_BANNER: false,
  REFER_A_FRIEND: false,
  RECHARGE_AMOUNT: false,
  ADD_METER: false,
  REACT_APP_ZENDESK_CHAT_ENABLE:
    process.env.REACT_APP_ZENDESK_CHAT_ENABLE === 'true' ? true : false,
  RENEWAL_BANNER: false,
  PLUS_BANNER: false,
  SHOW_SIGN_UP_LINK: true,
  PAYMENT_BELOW_BALANCE: true,
  DISABLE_BILLING_TOGGLES: false,
}

export const url = {
  SIGN_UP: isDev
    ? 'https://config.d3fbicgal063f7.amplifyapp.com'
    : 'https://join.abundanceenergy.com',
  ZENDESK_SECTIONS: '',
}

export const graphs = {
  usage_kwh: true,
  price_ckwh: false,
  cost_dollars: false,
}
