import { find, propEq, propOr } from 'ramda'
import { dateTZ, parseDate } from 'utils/dateUtils'

import { INTERVALS } from './constants'
import { disableAlignment } from 'utils/featureFlags'

export const getIntervalLabel = interval => {
  const intervalObj = find(propEq('value', interval))(INTERVALS)
  return propOr(INTERVALS[0].label, 'label')(intervalObj)
}

const composeArrayWithoutData = (numberOfEntries, startingDay, unit) =>
  new Array(numberOfEntries).fill(null).map((_, index) => ({
    time: parseDate(startingDay)
      .plus({ [`${unit}`]: index + 1 })
      .toUTC()
      .toISO(),
  }))

const appendEmptyDataToArray = (array, unit) => {
  if (array.length) {
    const lastEntry = dateTZ(array[array.length - 1].time)
    const now = dateTZ()
    let numberOfEntries = Math.floor(now.diff(lastEntry).as(unit))

    if (unit === 'months' && now < lastEntry.plus({ months: numberOfEntries }))
      numberOfEntries -= 1

    if (numberOfEntries > 0) {
      return array.concat(
        composeArrayWithoutData(numberOfEntries, lastEntry, unit)
      )
    }

    return array
  }

  return []
}

const addPending = (data, isMonth, shouldDo) =>
  shouldDo
    ? data.map(item => ({
        ...item,
        pending: !isMonth
          ? dateTZ()
              .diff(dateTZ(item.time))
              .as('days') <= 5
          : dateTZ()
              .diff(dateTZ(item.time).startOf('month'))
              .as('days') <= 5,
      }))
    : data

export const appendEmptyData = data => ({
  hourly: addPending(data.hourly, false, !disableAlignment),
  daily: appendEmptyDataToArray(
    addPending(data.daily, false, !disableAlignment),
    'days'
  ),
  monthly: appendEmptyDataToArray(
    addPending(data.monthly, true, !disableAlignment),
    'months'
  ),
})

export const sortIntervals = intervals =>
  intervals.slice().sort((a, b) => b.weight - a.weight)
