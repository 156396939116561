import styled, { css } from 'styled-components'
import mq from 'styles/mq'
import { zIndex } from 'styles/variables'
import { color } from 'styles/variables'

const itemHeight = 2
const itemGap = 4
const itemOffset = itemHeight + itemGap
const hamburgerHeight = 3 * itemHeight + 2 * itemGap
const hamburgerWidth = 18

export const HamburgerButton = styled.button`
  border: 0;
  padding: 0;
  background: none;
  width: ${hamburgerWidth}px;
  height: ${hamburgerHeight}px;
  position: relative;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  cursor: pointer;
  outline: none;
  margin-right: 3px;
  z-index: ${zIndex.navHamburger};
  overflow: hidden;

  /* prettier-ignore */
  ${mq.tablet`
    display: none;
  `}

  span {
    display: block;
    position: absolute;
    height: ${itemHeight}px;
    width: 100%;
    background: ${color.headerMobileNav ? color.headerMobileNav : color.primary};
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: 0.25s ease-in-out;
  }

  span:nth-child(1) {
    top: 0;
  }

  span:nth-child(2),
  span:nth-child(3) {
    top: ${itemOffset}px;
  }

  span:nth-child(4) {
    top: ${itemOffset * 2}px;
  }

  ${props =>
    props.isOpen &&
    css`
      span:nth-child(1) {
        top: ${itemHeight + itemGap}px;
        width: 0;
        left: 50%;
      }

      span:nth-child(2) {
        transform: rotate(45deg);
      }

      span:nth-child(3) {
        transform: rotate(-45deg);
      }

      span:nth-child(4) {
        top: ${itemOffset}px;
        width: 0;
        left: 50%;
      }
    `};
`
