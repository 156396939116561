import React from 'react'
import { Route, Redirect } from 'react-router-dom'

const redirects = {
  '#/statement': '/statement',
  '#/myaccount/account-max': '/account/recharge',
  '#/sign-up': '/sign-up',
  '#/login': '/login',
  '#/account': '/account',
}

const RedirectRoute = props => (
  <Route
    render={routeProps => {
      if (routeProps.location.hash) {
        return <Redirect to={redirects[routeProps.location.hash] || '/'} />
      }
      return <Route {...props} />
    }}
  />
)

export default RedirectRoute
