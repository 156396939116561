import { DateTime } from 'luxon'

const DEFAULT_TZ = 'America/Chicago'

export const parseDate = date => {
  if (!date) {
    return DateTime.local().setZone('America/Chicago')
  }

  if (date.isValid) {
    return date
  }

  if (typeof date === 'string') {
    if (date.split(' ').length > 1) {
      return DateTime.fromHTTP(date)
    }

    return DateTime.fromISO(date)
  }

  return DateTime.fromJSDate(date)
}

export function formatDate(date, formatString = 'yyyy-LL-dd') {
  return parseDate(date).toFormat(formatString)
}

export function dateTZ(date = DateTime.local(), tz = DEFAULT_TZ) {
  return parseDate(date).setZone(tz)
}

export const localeDateFormat = locale =>
  locale === 'es' ? "dd'/'MM'/'yy" : "MM'/'dd'/'yy"
