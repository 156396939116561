import { merge } from 'ramda'

import capitalize from 'utils/capitalize'
import {
  METER_LOOKUP_SUCCESS,
  METER_LOOKUP_FAILURE,
  SUBMIT_SERVICE_ADDRESS_FORM,
  SUBMIT_SELECT_ADDRESS_FORM,
  SUBMIT_SERVICE_INFO_FORM,
  SAVE_SERVICE_ADDRESS_VALUES,
  SAVE_SELECT_ADDRESS_VALUES,
  SAVE_SERVICE_INFO_VALUES,
  SAVE_START_DATE,
  SET_SHOULD_REDIRECT_TO_PAYMENT,
  MM_ENROLL_SUCCESS,
  MM_ENROLL_FAILURE,
  INIT_FORM,
  LOAD_REQUEST,
  LOAD_SUCCESS,
} from './constants'

export const initialState = {
  loading: false,
  error: '',
  meterID: '',
  metersForLocation: [],
  personalInformation: {},
  serviceInformation: {},
  serviceAddress: {},
  shouldRedirectToPayment: false,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_REQUEST: {
      return {
        ...state,
        loading: true,
      }
    }
    case LOAD_SUCCESS: {
      return {
        ...state,
        loading: false,
      }
    }

    case INIT_FORM: {
      const { abandonedMember } = action.payload

      if (abandonedMember) {
        const { address } = abandonedMember
        return merge(initialState, {
          serviceAddress: {
            line1: capitalize(address.street_address),
            postal_code: address.postal_code,
          },
        })
      }

      return initialState
    }

    case SAVE_SERVICE_ADDRESS_VALUES:
    case SUBMIT_SERVICE_ADDRESS_FORM:
      return merge(state, {
        serviceAddress: action.payload,
        meterID: '',
      })

    case METER_LOOKUP_SUCCESS:
      return merge(state, {
        meterID: initialState.meterID,
        metersForLocation: action.payload,
      })

    case SAVE_SELECT_ADDRESS_VALUES:
    case SUBMIT_SELECT_ADDRESS_FORM: {
      const { payload } = action

      if (!payload) return state

      const { address } = state.metersForLocation.find(
        location => location.esi === payload
      )
      return merge(state, {
        meterID: payload,
        serviceAddress: {
          line1: capitalize(address.line1),
          line2: address.line2,
          postal_code: address.postal_code,
        },
      })
    }

    case SAVE_SERVICE_INFO_VALUES:
    case SUBMIT_SERVICE_INFO_FORM:
      return merge(state, {
        serviceInformation: action.payload,
      })

    case SAVE_START_DATE: {
      return merge(state, {
        serviceInformation: merge(state.serviceInformation, action.payload),
      })
    }

    case METER_LOOKUP_FAILURE:
    case MM_ENROLL_FAILURE:
      return merge(state, {
        error: action.payload,
      })

    case SET_SHOULD_REDIRECT_TO_PAYMENT: {
      return merge(state, {
        shouldRedirectToPayment: action.payload,
      })
    }

    case MM_ENROLL_SUCCESS:
    default:
      return state
  }
}

export default reducer
