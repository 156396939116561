import { DateTime } from 'luxon'
import { localizeDate } from 'components/LocalizedDate'

export const aggregate = (context, searchDate, timeZone) => {
  const date = DateTime.fromISO(searchDate).setZone(timeZone)
  const dateObject = {
    year: Number(date.toFormat('yyyy')),
    month: Number(date.toFormat('LL')),
    day: Number(date.toFormat('dd')),
  }
  if (context === 'yearly') {
    dateObject.month = 1
    dateObject.day = 1
  } else if (context === 'monthly') {
    dateObject.day = 1
  }

  return DateTime.fromObject(dateObject).toFormat('yyyy-LL-dd')
}

export const setToMeterZone = (period, timeZone) => {
  const date = DateTime.fromISO(period).toFormat('yyyy-LL-dd')
  return DateTime.fromISO(date)
    .setZone(timeZone)
    .toISO()
}

export const isMeterPending = meterStatus =>
  meterStatus && meterStatus.includes('pending') // "pending_connection" || "pendingConnection"
export const isMeterDisconnected = meterStatus =>
  meterStatus && meterStatus.includes('disconnected')
export const isMeterPendingConnection = meterStatus =>
  meterStatus && meterStatus.includes('pending_connection')

export const getMonthNameFromNumber = (num, locale) =>
  localizeDate({ date: DateTime.local(2017, num, 10), format: 'LLL', locale })
