import { merge } from 'ramda'

import * as storage from 'utils/storage'
import * as mixpanel from 'utils/mixpanel'

import { CHANGE_LOCALE } from './constants'
import { DEFAULT_LOCALE } from '../app/constants'

const initialState = {
  locale: DEFAULT_LOCALE,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_LOCALE: {
      const { locale } = action
      storage.setLang(locale)
      mixpanel.updatePreferedLanguage(locale)
      return merge(state, { locale })
    }
    default:
      return state
  }
}

export default reducer
