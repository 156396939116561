import { createSelector } from 'reselect'

import { INTERVALS } from './constants'
import { sortIntervals } from './helpers'

export const selectUsageState = state => state.usage

export const selectTimeInterval = createSelector(
  selectUsageState,
  substate => substate.interval
)

export const selectHistory = createSelector(
  selectUsageState,
  substate => substate.history
)

export const selectIntervalInHistory = createSelector(
  [selectTimeInterval, selectHistory],
  (interval, history) => history && history[interval]
)

export const selectLoading = createSelector(
  selectUsageState,
  substate => substate.isLoading
)

export const selectIntervalsWithHistory = createSelector(
  selectHistory,
  history =>
    history
      ? INTERVALS.filter(
          ({ value: interval }) => history[interval] && history[interval].length
        )
      : []
)

export const selectSortedIntervalsWithHistory = createSelector(
  selectIntervalsWithHistory,
  intervals => sortIntervals(intervals)
)

export const selectIsHistory = createSelector(
  selectIntervalsWithHistory,
  intervals => Boolean(intervals.length)
)

export const selectMeterIdInUsage = createSelector(
  selectUsageState,
  usage => usage.meterID
)
