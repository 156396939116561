import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { I18nProvider } from '@lingui/react'
import { createStructuredSelector } from 'reselect'
import { parse } from 'query-string'

import * as storage from 'utils/storage'

import { changeLocale } from 'modules/language/actions'
import { selectLocale } from 'modules/language/selectors'

import en from 'locale/en-US/messages'
import es from 'locale/es-US/messages'

const catalogs = {
  en,
  es,
}

export class I18nLoader extends React.Component {
  componentWillMount = () => {
    // 1. check for query and set lang
    const location = window.location
    const { lang } = parse(location.search)
    if (lang && this.isInCatalog(lang)) {
      if (!lang.startsWith(this.props.language)) {
        this.setLocale(lang)
      }
      return
    }

    // 2. check for storage
    const currentLocale = storage.getLang()
    if (currentLocale) {
      if (currentLocale === this.props.language) {
        return
      }
      this.setLocale(currentLocale)
      return
    }

    // 3. check for browser lang
    const langs = this.getNavigatorLanguages()

    for (const index in langs) {
      if (langs.hasOwnProperty(index)) {
        const lng = langs[index]
        if (this.isInCatalog(lng)) {
          this.setLocale(lng)
          return
        }
      }
    }

    // 4. set default as en
    this.setLocale('en')
  }

  shouldComponentUpdate(nextProps) {
    if (this.props.language !== nextProps.language) {
      return true
    }
    return false
  }

  setLocale = value => {
    const lang = value.split('-')[0]
    storage.setLang(lang)
    this.props.changeLocale(lang)
  }

  isInCatalog = lng => {
    const catalogKeys = Object.keys(catalogs)
    return Boolean(catalogKeys.filter(key => lng.startsWith(key)).length)
  }

  getNavigatorLanguages = () => {
    const found = []

    if (typeof navigator !== 'undefined') {
      if (navigator.languages) {
        // chrome only; not an array, so can't use .push.apply instead of iterating
        for (let i = 0; i < navigator.languages.length; i++) {
          found.push(navigator.languages[i])
        }
      }
      if (navigator.userLanguage) {
        found.push(navigator.userLanguage)
      }
      if (navigator.language) {
        found.push(navigator.language)
      }
    }

    return found.length > 0 ? found : undefined
  }

  render() {
    const { children, language } = this.props

    return (
      <I18nProvider language={language} catalogs={catalogs}>
        {children}
      </I18nProvider>
    )
  }
}

I18nLoader.propTypes = {
  language: PropTypes.string.isRequired,
  changeLocale: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
}

const mapState = createStructuredSelector({
  language: selectLocale,
})

export default connect(mapState, { changeLocale })(I18nLoader)
