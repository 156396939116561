import React from 'react'

const Account = () => (
  <svg
    width="25"
    height="25"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 25 25"
  >
    <path
      fill="currentColor"
      d="M20.913141,21.150796l-.011173-.213244-.016566-.123574-.033325-.246088c-.048543-.327042-.07686-.651146-.16027-.968604-.13099-.63665-.317458-1.254374-.561909-1.836846-.481966-1.167545-1.203567-2.194709-2.072147-2.964853-1.758156-1.573325-3.913327-1.978527-5.584991-1.916355-1.298342-.031977-2.856353.157284-4.318433,1.007516-1.481151.802314-2.736151,2.294348-3.428086,4.075042-.33075.893814-.562101,1.846044-.665352,2.826158-.004045.070407-.026198.101999-.015025.204576l.02485.290249.050662.584784.051818.590226c.020997.091693-.012714.216422.064147.271371.067229.080087.134265.130701.192247.172406.130412.087407.304167.169709.526464.188683.226343.01021.46174.004864.693862.007994.286059.002119.573852.004286.862993.006405,1.916885.007946,3.911401.016229,5.960432.024753,1.441276-.004238,2.85539-.00838,4.209018-.012377.676525-.005153,1.337832-.01021,1.979876-.015122.207658-.001541.413967-.003082.618543-.004623.302433-.013292.636843.025572.89786-.041994.26487-.086636.399712-.169035.567302-.376789.071081-.224369.141585-.446618.211318-.666749-.014833-.289045-.029473-.576693-.044113-.862945ZM19.046148,21.111836l-.002504.068529c-.000385.014062-.00732.024512-.026198.015844-.037949-.003467.099398-.006742-.355214-.009728-.642044-.004912-1.303351-.010017-1.979876-.015218-1.353628-.003997-2.767742-.008187-4.209018-.012425-2.049031.008572-4.043547.016855-5.960432.024898l-.566532.006453-.028702.007176c-.015025.008909-.008283-.027113-.012521-.045702l-.002504-.035107-.001348-.018541.014062-.130123c.066843-.844838.239827-1.642721.481581-2.38503.51953-1.466751,1.428177-2.653848,2.561433-3.357727,1.112645-.743657,2.387293-1.001496,3.514385-1.075515,1.460346.078305,3.169766.548376,4.471961,1.843251.648401.637469,1.180644,1.461406,1.539325,2.424134.18146.479751.31977.994706.413967,1.534317.064339.264725.078016.547172.113075.826152l.023694.210595.011943.105611-.000578.018156Z"
    />
    <path
      fill="currentColor"
      d="M12.473145,11.090809c1.511394.001011,2.951514-.756419,3.773091-1.89088.851243-1.12454,1.103013-2.563552.828319-3.77834-.263906-1.232703-1.023841-2.223797-1.902245-2.80492-.880523-.599713-1.871424-.791093-2.69955-.740238-.828127-.050855-1.81922.140525-2.69955.740238-.878596.581172-1.638531,1.572266-1.902245,2.805065-.274886,1.214836-.022923,2.653945.828319,3.778484.821962,1.134557,2.262275,1.891939,3.773861,1.890591ZM9.620644,5.757107c.112883-.768603.534748-1.439735,1.073926-1.882115.538215-.454901,1.182185-.671854,1.77819-.730125.596005.058271,1.239975.275224,1.777997.730077.539178.442428.961236,1.113512,1.074118,1.882067.120588.768603-.077823,1.61922-.604095,2.27234-.514329.665689-1.358829,1.083509-2.247827,1.088903-.888999-.005201-1.733692-.423021-2.24802-1.08871-.526657-.653168-.725068-1.503785-.604288-2.272436Z"
    />
  </svg>
)

export default Account
