import React from 'react'
import { Link } from 'react-router-dom'

import { LinkButton as StyledLinkButton } from './styled'

const RouterLink = StyledLinkButton.withComponent(Link)
const A = StyledLinkButton.withComponent('a')

/* eslint-disable react/prop-types */
const LinkButton = props => {
  if (props.to) {
    return <RouterLink {...props} />
  }

  if (props.href) {
    return <A target="_blank" rel="noopener noreferrer" {...props} />
  }

  return <StyledLinkButton {...props} />
}

export default LinkButton
