import { fork, call, put, select, takeLatest } from 'redux-saga/effects'

import * as API from 'api'

import {
  selectCurrentMeterUID,
  selectIsGriddyGuest,
} from 'modules/members/selectors'

import {
  selectIsHistory,
  selectSortedIntervalsWithHistory,
  selectTimeInterval,
} from './selectors'

import {
  getHistoryRequest,
  getHistorySuccess,
  getHistoryFailure,
  setTimeInterval,
} from './actions'

import { SET_TIME_INTERVAL } from './constants'

import { appendEmptyData } from './helpers'

function* historySaga() {
  yield put(getHistoryRequest())
  const ID = yield select(selectCurrentMeterUID)
  yield call(getHistorySaga, { ID })
}

export function* getHistorySaga({ ID }) {
  const interval = yield select(selectTimeInterval)

  try {
    const isGriddyGuest = yield select(selectIsGriddyGuest)
    const apiEndpoint = isGriddyGuest ? API.getGuestHistory : API.getHistory
    const response = yield call(apiEndpoint, {
      meterID: ID,
    })
    yield put(getHistorySuccess(ID, appendEmptyData(response)))

    yield setTimeIntervalSaga(interval)
  } catch (error) {
    console.error(error)
    yield put(getHistoryFailure(error))
  }
}

function* setTimeIntervalSaga({ interval }) {
  const intervalsWithHistory = yield select(selectSortedIntervalsWithHistory)
  const isHistory = yield select(selectIsHistory)

  // do we have history at all?
  if (isHistory) {
    // is selected interval in history?
    const found = intervalsWithHistory.find(
      currentInterval => currentInterval.value === interval
    )
    if (!found) {
      // if not select first history interval sorted by weight
      yield put(setTimeInterval(intervalsWithHistory[0].value))
    }
  }
}

export default function* defaultSaga() {
  yield fork(historySaga)
  yield takeLatest(SET_TIME_INTERVAL, setTimeIntervalSaga)
}
