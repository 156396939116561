import styled, { css, keyframes } from 'styled-components'
import { style } from 'styled-system'

import { easing } from 'styles/variables'

const pulse = keyframes`
  0% {
    transform: scale(1);
    opacity: 1;
  }
  45% {
    transform: scale(0.1);
    opacity: 0.7;
  }
  80% {
    transform: scale(1);
    opacity: 1;
  }
`

export const Dot = styled.div`
  border-radius: 100%;
  display: inline-block;
  vertical-align: top;
  animation: ${pulse} 0.75s 0.12s infinite ${easing.easeOutCubic};
  animation-fill-mode: both;

  &:nth-child(1) {
    animation-delay: 0.12s;
  }
  &:nth-child(2) {
    animation-delay: 0.24s;
  }
  &:nth-child(3) {
    animation-delay: 0.36s;
  }
`

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${style({
    prop: 'height',
    cssProperty: 'height',
  })};

  ${props => css`
    ${/* sc-custom 'div' */ Dot} {
      background-color: ${props.color};
      width: ${props.size}px;
      height: ${props.size}px;
      margin: 0 4px;
    }
  `};
`

export const PageWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`
