import { merge } from 'ramda'

import { DateTime } from 'luxon'
import formatPhone from 'utils/formatPhone'
import capitalize from 'utils/capitalize'
import {
  METER_LOOKUP_SUCCESS,
  METER_LOOKUP_FAILURE,
  SUBMIT_SERVICE_ADDRESS_FORM,
  SUBMIT_SELECT_ADDRESS_FORM,
  SUBMIT_SERVICE_INFO_FORM,
  SUBMIT_PERSONAL_INFO_FORM,
  SAVE_SERVICE_ADDRESS_VALUES,
  SAVE_SELECT_ADDRESS_VALUES,
  SAVE_SERVICE_INFO_VALUES,
  SAVE_PERSONAL_INFO_VALUES,
  SAVE_START_DATE,
  SET_SHOULD_REDIRECT_TO_PAYMENT,
  ENROLL_SUCCESS,
  ENROLL_FAILURE,
  POPULATE,
} from './constants'

import { RESET_PROMO, UPDATE_PROMO } from 'modules/promos/constants'

export const initialState = {
  error: '',
  meterID: '',
  metersForLocation: [],
  personalInformation: {},
  serviceInformation: {},
  serviceAddress: {},
  shouldRedirectToPayment: false,
  finished: false,
  promo: {},
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_SERVICE_ADDRESS_VALUES:
    case SUBMIT_SERVICE_ADDRESS_FORM:
      return merge(state, {
        serviceAddress: action.payload,
        meterID: '',
      })

    case METER_LOOKUP_SUCCESS:
      return merge(state, {
        meterID: initialState.meterID,
        metersForLocation: action.payload,
      })

    case SAVE_SELECT_ADDRESS_VALUES:
    case SUBMIT_SELECT_ADDRESS_FORM: {
      const { payload } = action

      if (!payload) return state

      const { address } = state.metersForLocation.find(
        location => location.esi === payload
      )
      return merge(state, {
        meterID: payload,
        serviceAddress: {
          line1: capitalize(address.line1),
          line2: address.line2,
          postal_code: address.postal_code,
        },
      })
    }

    case SAVE_SERVICE_INFO_VALUES:
    case SUBMIT_SERVICE_INFO_FORM:
      return merge(state, {
        serviceInformation: action.payload,
      })

    case SAVE_PERSONAL_INFO_VALUES:
    case SUBMIT_PERSONAL_INFO_FORM:
      return merge(state, {
        personalInformation: action.payload,
      })

    case SAVE_START_DATE: {
      return merge(state, {
        serviceInformation: merge(state.serviceInformation, action.payload),
      })
    }

    case METER_LOOKUP_FAILURE:
    case ENROLL_FAILURE:
      return merge(state, {
        error: action.payload,
      })

    case SET_SHOULD_REDIRECT_TO_PAYMENT: {
      return merge(state, {
        shouldRedirectToPayment: action.payload,
      })
    }

    case ENROLL_SUCCESS: {
      return merge(state, { finished: true })
    }

    case POPULATE: {
      const { address, phone, first_name, last_name, birthday } = action.data
      return merge(state, {
        serviceAddress: {
          line1: capitalize(address.street_address),
          postal_code: address.postal_code,
        },
        personalInformation: {
          first_name,
          last_name,
          birthday: DateTime.fromISO(birthday, { zone: 'utc' }).toFormat(
            'LL/dd/yyyy'
          ),
          phone: formatPhone(phone),
        },
      })
    }

    case UPDATE_PROMO: {
      return merge(state, {
        promo: action.payload,
      })
    }

    case RESET_PROMO: {
      return merge(state, {
        promo: { unit: '', value: 0, id: '0' },
      })
    }

    default:
      return state
  }
}

export default reducer
