import { merge } from 'ramda'

import {
  SELECT_SECTION,
  GET_SECTIONS_SUCCESS,
  GET_ARTICLES_REQUEST,
  GET_ARTICLES_SUCCESS,
} from './constants'

const initialState = {
  loading: false,
  activeSectionID: null,
  sections: [],
  articles: {},
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SELECT_SECTION: {
      const { sectionID } = action
      return merge(state, { activeSectionID: sectionID })
    }

    case GET_ARTICLES_REQUEST: {
      return merge(state, { loading: true })
    }

    case GET_SECTIONS_SUCCESS: {
      const { sections } = action
      return merge(state, { sections, loading: false })
    }

    case GET_ARTICLES_SUCCESS: {
      const { sectionID, articles } = action
      return merge(state, {
        articles: { ...state.articles, [sectionID]: articles },
      })
    }

    default:
      return state
  }
}

export default reducer
