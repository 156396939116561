import * as C from './constants'

export const getRenewalOfferRequest = payload => ({
  type: C.GET_RENEWAL_OFFER_REQUEST,
  payload,
})

export const getRenewalOfferSuccess = payload => ({
  type: C.RENEWAL_OFFER_SUCCESS,
  payload,
})

export const getRenewalOfferFailure = payload => ({
  type: C.RENEWAL_OFFER_FAILURE,
  payload,
})

export const selectRenewalOffer = payload => ({
  type: C.SELECT_OFFER,
  payload,
})

export const submitRenewalOfferForm = (payload, setters) => ({
  type: C.SUBMIT_RENEWAL_OFFER_FORM,
  payload,
  setters,
})

export const submitRenewalOfferFormSuccess = payload => ({
  type: C.SUBMIT_RENEWAL_OFFER_FORM_SUCCESS,
  payload,
})

export const submitRenewalOfferFormFailure = payload => ({
  type: C.SUBMIT_RENEWAL_OFFER_FORM_FAILURE,
  payload,
})

export const hideRenewalPage = payload => ({
  type: C.HIDE_RENEWAL_PAGE,
  payload,
})

export const clearRenewalEnrollmentData = () => ({
  type: C.CLEAR_RENEWAL_ENROLLMENT_DATA,
})
