import styled from 'styled-components'
import mq, { breakpoints } from 'styles/mq'
import { color as colors } from 'styles/variables'

export const Wrapper = styled.div`
  @media (max-width: ${breakpoints.tablet - 1}px) {
    max-height: ${props => (props.isOpen ? '500px' : '0')};
    height: auto;
    padding-bottom: ${props => (props.isOpen ? '10px' : '0')};
    overflow: hidden;
    transition: max-height 0.3s ease;
  }

  ${mq.tablet`
    max-height: 500px;
    min-width: 300px;
    max-width: 357px;
    position: absolute;
    top: ${props => (props.isOpen ? '65px' : '-500px')};
    right: 2px;
    padding-bottom: 10px;
    transition: top 0.4s ease;
  `};
`

export const StyledNewscard = styled.div`
  padding: 30px 24px;
  background: white;
  border: 1px solid #eee;
  border-radius: 30px;
  margin: 12px 9px 10px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.15);
`
export const CloseButton = styled.button`
  color: ${colors.secondary};
  padding: 0;
  cursor: pointer;
  margin: -8px 0 -2px 0;

  &:hover {
    color: #000;
  }
`
