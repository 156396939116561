import styled from 'styled-components'
import { space, textAlign, position, top, bottom } from 'styled-system'
import { color } from 'styles/variables'
import { linkStyles } from 'components/buttons/LinkButton/styled'

const ErrorMessage = styled.div.attrs({
  className: 'error',
})`
  width: 100%;
  font-size: 1.4rem;
  color: ${props => {
    if (props.valid) return color.base
    if (props.untouched) return color.secondary
    return color.error
  }};

  a {
    ${linkStyles};
  }
  ${space};
  ${textAlign};
  ${position};
  ${top};
  ${bottom};
`

export default ErrorMessage
