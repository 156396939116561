import { all, takeLatest, call, put, select, fork } from 'redux-saga/effects'
import { delay } from 'redux-saga'
import { DateTime } from 'luxon'
import { pathOr } from 'ramda'
import * as API from 'api'

import {
  selectSettlementPoint,
  selectCurrentMeter,
} from 'modules/members/selectors'
import { selectWeather } from './selectors'

import {
  getCostsRequest,
  getCostsSuccess,
  getCostsFailure,
  getWeatherSuccess,
  getWeatherFailure,
} from './actions'
import * as C from './constants'

function* costSaga() {
  yield put(getCostsRequest())
  const settlementPoint = yield select(selectSettlementPoint)
  yield call(getCostsSaga, { settlementPoint })
  yield call(getWeatherSaga)
}

export function* getCostsSaga({ settlementPoint }) {
  try {
    const response = yield call(API.getNow, {
      settlement_point: settlementPoint || 'LZ_HOUSTON',
    })
    const refreshAt = DateTime.local().plus({
      seconds: Number(response.seconds_until_refresh),
    })
    yield put(
      getCostsSuccess({
        ...response,
        refreshAt: refreshAt.toString(),
      })
    )
  } catch (error) {
    yield put(getCostsFailure(error))
    yield delay(5000)
    yield call(getCostsSaga, { settlementPoint })
  }
}

function* refetchCostsSaga({ payload: { seconds_until_refresh } }) {
  yield delay(Number(seconds_until_refresh) * 1000)
  const settlementPoint = yield select(selectSettlementPoint)
  yield call(getCostsSaga, { settlementPoint })
}

function* getWeatherSaga() {
  const meter = yield select(selectCurrentMeter)
  const prevWeather = yield select(selectWeather)

  const isNewMeter = prevWeather.meterRecordID !== meter.ID
  const prevWeatherTime = pathOr(0, ['currently', 'time'], prevWeather)
  const shouldFetch =
    DateTime.utc()
      .minus({ hour: 1 })
      .toSeconds() >= prevWeatherTime

  if (isNewMeter || shouldFetch) {
    try {
      const response = yield call(API.weatherForecast, { ID: meter.ID })
      yield put(
        getWeatherSuccess({
          ...response,
          meterRecordID: meter.ID,
        })
      )
    } catch (error) {
      yield put(getWeatherFailure(error))
    }
  }
}
function* refetchWeatherSaga() {
  yield delay(3600 * 1000)
  yield call(getWeatherSaga)
}

export default function* defaultSaga() {
  yield all([
    fork(costSaga),
    takeLatest(C.GET_COSTS_SUCCESS, refetchCostsSaga),
    takeLatest(C.GET_WEATHER_SUCCESS, refetchWeatherSaga),
  ])
}
