import { createSelector } from 'reselect'
import { groupBy, keys } from 'ramda'
import { DateTime } from 'luxon'
import { selectTimeZone } from 'modules/members/selectors'
import { selectLocale } from 'modules/language/selectors'
import depositCopyFormatter from 'utils/depositCopyFormatter'
import { pathOr } from 'ramda'

export const selectAccount = state => state.account

export const selectBilling = createSelector(selectAccount, state => {
  return {
    billingMethod: state.billingMethod,
    autopay: state.autopay,
    statement_delivery: state.statement_delivery,

  }
})

export const selectBalance = createSelector(
  selectAccount,
  state => state.balance
)

export const selectNetMeteringHistory = createSelector(
  selectAccount,
  state => state.netMeteringHistory,
)

export const selectAccountID = createSelector(
  selectAccount,
  state => state.accountID
)

export const selectAccountBalance = createSelector(
  selectAccount,
  state => state.accountBalance
)

export const selectCards = createSelector(selectAccount, state => state.cards)

export const selectPrimaryCard = createSelector(
  selectCards,
  cards => cards.find(card => card.default === true) || {}
)

export const selectAlternateCards = createSelector(selectCards, cards =>
  cards.filter(card => card.default !== true)
)

export const selectLoading = createSelector(
  selectAccount,
  state => state.loading
)

export const selectSubmitResult = createSelector(
  selectAccount,
  state => state.submitResult
)

export const makeSelectError = () =>
  createSelector(selectAccount, substate => pathOr('', ['error'], substate))

export const selectPaginationLoading = createSelector(
  selectAccount,
  account => account.paginationLoading
)

export const selectCanDepositPaginate = createSelector(
  selectAccount,
  account => account.canPaginateDeposits
)

export const selectAccountNumber = createSelector(
  selectAccount,
  account => account.accountNumber
)

export const selectBillingName = createSelector(
  selectAccount,
  account => account.bill_to_name
)

export const selectBillingAddress = createSelector(
  selectAccount,
  substate => substate.billing_address
)

const selectFormattedDeposits = createSelector(selectAccount, account => {
  const { deposits } = account
  return deposits.map(deposit => {
    deposit.description = depositCopyFormatter(deposit)
    return deposit
  })
})

export const selectRecentDeposits = createSelector(
  selectFormattedDeposits,
  deposits => deposits.slice(0, 2)
)

export const selectDepositHistory = createSelector(
  selectFormattedDeposits,
  selectTimeZone,
  selectLocale,
  (deposits, timeZone, locale) => {
    const groupedByMonthYear = groupBy(deposit =>
      DateTime.fromISO(deposit.created)
        .setLocale(locale)
        .setZone(timeZone)
        .toFormat("LLLL'_'yyyy")
    )(deposits)
    return keys(groupedByMonthYear).map(key => ({
      title: key.split('_').join(' '),
      deposits: groupedByMonthYear[key],
    }))
  }
)

export const selectProducts = createSelector(
  selectAccount,
  state => state.products
)

export const selectLatestProduct = createSelector(
  selectAccount,
  state => state.latestProduct
)

export const selectShowAutoPayModal = createSelector(
  selectAccount,
  account => account.showAutoPayModal
)

export const selectAccountStatement = createSelector(
  selectAccount,
  state => state.accountStatement
)