export const LOAD_DEVICES_PAGE = 'devices/LOAD_DEVICES_PAGE'

export const LIST_MEMBER_DEVICES_REQUEST = 'devices/LIST_MEMBER_DEVICES_REQUEST'
export const LIST_MEMBER_DEVICES_SUCCESS = 'devices/LIST_MEMBER_DEVICES_SUCCESS'
export const LIST_MEMBER_DEVICES_FAILURE = 'devices/LIST_MEMBER_DEVICES_FAILURE'

export const LINK_MEMBER_DEVICES_REQUEST = 'devices/LINK_MEMBER_DEVICES_REQUEST'
export const LINK_MEMBER_DEVICES_SUCCESS = 'devices/LINK_MEMBER_DEVICES_SUCCESS'
export const LINK_MEMBER_DEVICES_FAILURE = 'devices/LINK_MEMBER_DEVICES_FAILURE'
export const SET_LINKING_STATES = 'devices/SET_LINKING_STATES'

export const REMOVE_MEMBER_DEVICES_REQUEST =
  'devices/REMOVE_MEMBER_DEVICES_REQUEST'
export const REMOVE_MEMBER_DEVICES_SUCCESS =
  'devices/REMOVE_MEMBER_DEVICES_SUCCESS'
export const REMOVE_MEMBER_DEVICES_FAILURE =
  'devices/REMOVE_MEMBER_DEVICES_FAILURE'

export const UNLINK_MEMBER_DEVICES_REQUEST =
  'devices/UNLINK_MEMBER_DEVICES_REQUEST'
export const UNLINK_MEMBER_DEVICES_SUCCESS =
  'devices/UNLINK_MEMBER_DEVICES_SUCCESS'
export const UNLINK_MEMBER_DEVICES_FAILURE =
  'devices/UNLINK_MEMBER_DEVICES_FAILURE'

export const CAPTURE_LINK_DEVICES_ACKNOWLEDGEMENT_REQUEST =
  'devices/CAPTURE_LINK_DEVICES_ACKNOWLEDGEMENT_REQUEST'
export const CAPTURE_LINK_DEVICES_ACKNOWLEDGEMENT_SUCCESS =
  'devices/CAPTURE_LINK_DEVICES_ACKNOWLEDGEMENT_SUCCESS'
export const CAPTURE_LINK_DEVICES_ACKNOWLEDGEMENT_FAILURE =
  'devices/CAPTURE_LINK_DEVICES_ACKNOWLEDGEMENT_FAILURE'

// TBD
export const REMOVE_MEMBER_DEVICE_REQUEST =
  'devices/REMOVE_MEMBER_DEVICE_REQUEST'
export const REMOVE_MEMBER_DEVICE_SUCCESS =
  'devices/REMOVE_MEMBER_DEVICE_SUCCESS'
export const REMOVE_MEMBER_DEVICE_FAILURE =
  'devices/REMOVE_MEMBER_DEVICE_FAILURE'

// TBD
export const SCHEDULE_MEMBER_DEVICE_REQUEST =
  'devices/SCHEDULE_MEMBER_DEVICE_REQUEST'
export const SCHEDULE_MEMBER_DEVICE_SUCCESS =
  'devices/SCHEDULE_MEMBER_DEVICE_SUCCESS'
export const SCHEDULE_MEMBER_DEVICE_FAILURE =
  'devices/SCHEDULE_MEMBER_DEVICE_FAILURE'
