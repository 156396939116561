import { merge } from 'ramda'

import {
  LINK_MEMBER_DEVICES_REQUEST,
  LINK_MEMBER_DEVICES_SUCCESS,
  LINK_MEMBER_DEVICES_FAILURE,
  REMOVE_MEMBER_DEVICES_REQUEST,
  REMOVE_MEMBER_DEVICES_SUCCESS,
  REMOVE_MEMBER_DEVICES_FAILURE,
  UNLINK_MEMBER_DEVICES_REQUEST,
  UNLINK_MEMBER_DEVICES_SUCCESS,
  UNLINK_MEMBER_DEVICES_FAILURE,
  LIST_MEMBER_DEVICES_REQUEST,
  LIST_MEMBER_DEVICES_SUCCESS,
  LIST_MEMBER_DEVICES_FAILURE,
  CAPTURE_LINK_DEVICES_ACKNOWLEDGEMENT_REQUEST,
  CAPTURE_LINK_DEVICES_ACKNOWLEDGEMENT_SUCCESS,
  CAPTURE_LINK_DEVICES_ACKNOWLEDGEMENT_FAILURE,
  SET_LINKING_STATES,
} from './constants'

export const initialState = {
  isLoading: false,
  error: '',
  devices: [],
  linkUrl: '', // retry if popup is not enabled
  isPopupEnabled: null, // controls retry modal when set
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case UNLINK_MEMBER_DEVICES_REQUEST:
    case REMOVE_MEMBER_DEVICES_REQUEST:
    case CAPTURE_LINK_DEVICES_ACKNOWLEDGEMENT_REQUEST:
    case LINK_MEMBER_DEVICES_REQUEST:
    case LIST_MEMBER_DEVICES_REQUEST: {
      return merge(state, { error: '', isLoading: true })
    }
    case UNLINK_MEMBER_DEVICES_SUCCESS:
    case REMOVE_MEMBER_DEVICES_SUCCESS:
    case LINK_MEMBER_DEVICES_SUCCESS: {
      return merge(state, { isLoading: false, error: '' })
    }
    case SET_LINKING_STATES: {
      const isPopupEnabled =
        action.payload?.isPopupEnabled === undefined
          ? null
          : action.payload?.isPopupEnabled
      const linkUrl = action.payload.linkUrl

      return merge(state, {
        isLoading: false,
        isPopupEnabled: isPopupEnabled,
        linkUrl: linkUrl,
      })
    }
    case LIST_MEMBER_DEVICES_SUCCESS: {
      return merge(state, {
        devices: action.payload,
        error: '',
        isLoading: false,
      })
    }
    case CAPTURE_LINK_DEVICES_ACKNOWLEDGEMENT_SUCCESS: {
      return merge(state, { isLoading: false })
    }
    case UNLINK_MEMBER_DEVICES_FAILURE:
    case REMOVE_MEMBER_DEVICES_FAILURE:
    case CAPTURE_LINK_DEVICES_ACKNOWLEDGEMENT_FAILURE:
    case LINK_MEMBER_DEVICES_FAILURE:
    case LIST_MEMBER_DEVICES_FAILURE: {
      return merge(state, { error: action.payload, isLoading: false })
    }
    default:
      return state
  }
}

export default reducer
