import * as squatch from '@saasquatch/squatch-js'

import { SAASQUATCH_TENANT_ALIAS } from '../config'

export const upsert = (userID, email, converted, firstName, lastName) => {
  squatch.ready(() => {
    squatch.init({
      tenantAlias: SAASQUATCH_TENANT_ALIAS,
    })

    squatch.api().upsertUser({
      user: {
        id: userID,
        accountId: userID,
        email,
        firstName,
        lastName,
        referredBy: {
          isConverted: converted,
        },
      },
    })
  })
}
