import React from 'react'

import { CloseButton } from './styled'
import Close from 'assets/images/icons/Close'

const Button = props => (
  <CloseButton {...props}>
    <Close />
  </CloseButton>
)

export default Button
