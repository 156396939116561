import styled, { css } from 'styled-components'
import { space, color } from 'styled-system'

import { color as colors } from 'styles/variables'

export const linkStyles = css`
  cursor: pointer;
  color: ${colors.link};

  &:hover {
    text-decoration: underline;
  }

  ${color};
`

export const LinkButton = styled.button`
  ${linkStyles};
  ${space};
`
