import * as C from './constants'

export const meterLookupRequest = () => ({
  type: C.METER_LOOKUP_REQUEST,
})

export const meterLookupFailure = payload => ({
  type: C.METER_LOOKUP_FAILURE,
  payload,
})

export const meterLookupSuccess = payload => ({
  type: C.METER_LOOKUP_SUCCESS,
  payload,
})

export const enrollRequest = () => ({
  type: C.ENROLL_REQUEST,
})

export const enrollFailure = payload => ({
  type: C.ENROLL_FAILURE,
  payload,
})

export const enrollSuccess = mixpanelProps => ({
  type: C.ENROLL_SUCCESS,
  mixpanelProps,
})

/**
 * FORMS SUBMIT
 */

export const submitSignUpForm = (payload, setters) => ({
  type: C.SUBMIT_SIGN_UP_FORM,
  payload,
  setters,
})

export const submitServiceAddressForm = (payload, setters) => ({
  type: C.SUBMIT_SERVICE_ADDRESS_FORM,
  payload,
  setters,
})

export const submitSelectAddressForm = payload => ({
  type: C.SUBMIT_SELECT_ADDRESS_FORM,
  payload,
})

export const submitServiceInfoForm = (payload, setters) => ({
  type: C.SUBMIT_SERVICE_INFO_FORM,
  payload,
  setters,
})

export const submitPersonalInfoForm = (payload, setters) => ({
  type: C.SUBMIT_PERSONAL_INFO_FORM,
  payload,
  setters,
})

export const submitPaymentForm = (payload, setters) => ({
  type: C.SUBMIT_PAYMENT_FORM,
  payload,
  setters,
})

/**
 * FORMS VALUES SAVE
 */

export const saveServiceAddressValues = payload => ({
  type: C.SAVE_SERVICE_ADDRESS_VALUES,
  payload,
})
export const saveSelectAddressValues = payload => ({
  type: C.SAVE_SELECT_ADDRESS_VALUES,
  payload,
})

export const saveServiceInfoValues = payload => ({
  type: C.SAVE_SERVICE_INFO_VALUES,
  payload,
})

export const savePersonalInfoValues = payload => ({
  type: C.SAVE_PERSONAL_INFO_VALUES,
  payload,
})

export const setShouldRedirectToPayment = payload => ({
  type: C.SET_SHOULD_REDIRECT_TO_PAYMENT,
  payload,
})

export const saveStartDate = payload => ({
  type: C.SAVE_START_DATE,
  payload,
})

export const addCardFailure = () => ({
  type: C.ADD_CARD_FAILURE,
})

export const addCardSuccess = () => ({
  type: C.ADD_CARD_SUCCESS,
})

export const addFundsFailure = () => ({
  type: C.ADD_FUNDS_FAILURE,
})

export const addFundsSuccess = () => ({
  type: C.ADD_FUNDS_SUCCESS,
})

export const addMeterFailure = () => ({
  type: C.ADD_METER_FAILURE,
})

export const addMeterSuccess = () => ({
  type: C.ADD_METER_SUCCESS,
})

export const populate = data => ({
  type: C.POPULATE,
  data,
})
