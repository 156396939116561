import styled from 'styled-components'
import { width, fontSize, space } from 'styled-system'

import mq from 'styles/mq'
import { color, font, input } from 'styles/variables'
import arrowWhite from 'assets/images/icons/arrow-white.svg'

export const Wrapper = styled.div`
  position: relative;
`

export const Button = styled.button`
  font-family: ${font.customFamily};
  font-weight: ${font.extraBold};
  font-size: 1.8rem;
  color: #fff;
  letter-spacing: 0.2px;
  position: relative;
  padding-right: 26px;

  ::before {
    content: '';
    background: url(${arrowWhite}) no-repeat center center;
    width: 12px;
    height: 8px;
    position: absolute;
    top: 8px;
    right: 0;
    transition: transform 0.3s ease;
    ${props => props.isOpen && 'transform: rotate(180deg);'};
  }
`

export const List = styled.ul`
  position: absolute;
  top: 32px;
  right: 0;
  width: 200px;
  list-style: none;
  padding: 4px 0;
  margin: 0;
  background: #fff;
  border-radius: 4px;
  border: 1px solid #d5dee5;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
  transition: 0.2s visibility, 0.2s opacity;
  ${width};
`

export const Section = styled.section`
  padding: 5px 0;
  border-top: 1px solid #d5dee5;
  max-height: 220px;
  overflow: auto;
  ${space};
`

export const ListItem = styled.li`
  display: block;
  text-align: left;
`

export const ListButton = styled.button`
  width: 100%;
  font-family: ${font.normal};
  text-align: left;
  padding: 7px 15px;
  color: ${color.base};
  transition: 0.2s background;
  font-size: 1.6rem;
  cursor: pointer;
  ${fontSize};
  ${space};

  &:hover {
    background-color: #f2f3f6;
  }
`

export const MeterButton = styled.button`
  width: 100%;
  display: ${({ hide }) => (hide ? 'none' : 'flex')};
  align-items: center;
  text-align: left;
  padding: 12px 15px;
  cursor: pointer;

  ${mq.tablet`
    &:hover {
      background: #f2f3f6;
    }
  `};
`

export const Radio = styled.div`
  position: relative;
  width: 25px;
  height: 25px;
  flex: 0 0 25px;
  background: #fff;
  border-radius: 50%;
  border: 1px solid ${input.borderColorFocused};
  margin-right: 15px;

  &::after {
    content: '';
    position: absolute;
    top: 4px;
    left: 4px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: ${props => (props.isChecked ? color.primary : '#fff')};
  }
`
