import { find, propEq } from 'ramda'

import {
  GET_MEMBERS_SUCCESS,
  GET_ENROLLMENT_SUCCESS,
  GET_ENROLLMENT_FAILURE,
  SET_CURRENT_MEMBER,
  UPDATE_MEMBER_SUCCESS,
  ADD_MEMBER_SUCCESS,
  GET_GUESTS_SUCCESS,
  CREATE_MEMBER_SUCCESS,
  CREATE_GUEST_SUCCESS,
} from './constants'

export const initialState = []

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_GUESTS_SUCCESS: {
      return action.payload
    }

    case GET_MEMBERS_SUCCESS: {
      return action.payload.map(member => {
        const memberInState = find(propEq('memberID', member.memberID))(state)
        if (!memberInState) return member
        return {
          ...memberInState,
          ...member,
        }
      })
    }

    case GET_ENROLLMENT_SUCCESS: {
      const { memberID, balance, meter } = action.payload
      return state.map(member => {
        if (memberID !== member.memberID) return member
        return {
          ...member,
          balance,
          meter,
        }
      })
    }

    case GET_ENROLLMENT_FAILURE: {
      const { memberID } = action.payload
      return state.map(member => {
        if (memberID !== member.memberID) return member
        return {
          ...member,
          balance: null,
          meter: null,
        }
      })
    }

    case CREATE_GUEST_SUCCESS: {
      const { guest } = action
      return [{ ...guest, isGriddyGuest: true }]
    }

    case CREATE_MEMBER_SUCCESS: {
      const { member } = action
      return [{ ...member, isSelected: true }]
    }

    case ADD_MEMBER_SUCCESS: {
      const { member } = action
      return state.concat([{ ...member, isSelected: false }])
    }

    case SET_CURRENT_MEMBER: {
      const { memberID } = action.payload
      return state.map(member => {
        if (memberID === member.memberID) {
          return { ...member, isSelected: true }
        }
        return { ...member, isSelected: false }
      })
    }

    case UPDATE_MEMBER_SUCCESS: {
      const { memberID } = action.payload
      return state.map(member => {
        if (memberID === member.memberID) {
          return { ...member, ...action.payload }
        }
        return member
      })
    }

    default:
      return state
  }
}

export default reducer
