import React from 'react'

const Wholesale = () => (
  <svg width="26" height="26" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h26v26H0z" />
      <path
        d="M3.74 16.06a2.167 2.167 0 0 0 1.98 3.429 9.755 9.755 0 0 0 3.41 2.463 1.083 1.083 0 1 1-.861 1.988A11.918 11.918 0 0 1 1.083 13C1.083 6.419 6.42 1.083 13 1.083c6.581 0 11.917 5.336 11.917 11.917 0 4.72-2.77 8.937-6.994 10.855a1.083 1.083 0 1 1-.896-1.973A9.75 9.75 0 0 0 22.75 13c0-5.385-4.365-9.75-9.75-9.75S3.25 7.615 3.25 13c0 1.061.171 2.091.49 3.06zm3.877-2.454l-1.995 1.152a7.583 7.583 0 1 1 10.419 5.19 1.083 1.083 0 1 1-.87-1.984 5.418 5.418 0 1 0-7.554-4.358zm2.166 3.753c.312.23.65.429 1.012.59a1.083 1.083 0 0 1-.883 1.98A7.587 7.587 0 0 1 7.79 18.51l1.994-1.15zm2.133-2.482l-5.88 3.395a1.083 1.083 0 1 1-1.083-1.877L10.833 13a2.167 2.167 0 1 1 1.083 1.877z"
        fill="currentColor"
        fillRule="nonzero"
      />
    </g>
  </svg>
)

export default Wholesale
