import * as C from './constants'

export const getCostsRequest = () => ({
  type: C.GET_COSTS_REQUEST,
})

export const getCostsSuccess = payload => ({
  type: C.GET_COSTS_SUCCESS,
  payload,
})

export const getCostsFailure = () => ({
  type: C.GET_COSTS_FAILURE,
})

export const getWeatherSuccess = payload => ({
  type: C.GET_WEATHER_SUCCESS,
  payload,
})

export const getWeatherFailure = () => ({
  type: C.GET_WEATHER_FAILURE,
})
