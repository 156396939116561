import { companyInfo } from './profile'
export const API_HOST = process.env.REACT_APP_BASE_API_URL
export const CHASE_GETKEYS_URL = process.env.REACT_APP_CHASE_GETKEYS_URL
export const CHASE_ENRCRYPTION_URL = process.env.REACT_APP_CHASE_ENRCRYPTION_URL
export const CHASE_ENABLE_PIE = process.env.REACT_APP_CHASE_ENABLE_PIE
export const CHASE_SUBSCRIBER_ID = process.env.REACT_APP_CHASE_SUBSCRIBER_ID
export const ACCEPTED_PAYMENT_CARD_TYPES =
  process.env.REACT_APP_ACCEPTED_PAYMENT_CARD_TYPES
export const NODE_ENV = process.env.NODE_EN

export const isDev = !window.location.href.includes('app.gogriddy')
export const STRIPE_KEY = process.env.REACT_APP_STRIPE_KEY
export const SAASQUATCH_TENANT_ALIAS = isDev ? 'test_ai0zqe5id7tzt' : 'asxraqgny216e' // prettier-ignore
export const MIXPANEL_TOKEN = companyInfo.mixpanelToken

// Both keys are for dev sandbox to start with
export const ITERABLE_KEY = isDev ? '52dba399695e48679c7e5ebbb8e4af78' : '52dba399695e48679c7e5ebbb8e4af78' // prettier-ignore
export const ITERABLE_URL = 'https://api.iterable.com/api/' // prettier-ignore
export const VGS_VAULT_ID = process.env.REACT_APP_VGS_VAULT_ID
export const VGS_ENVIRONMENT = process.env.REACT_APP_VGS_ENVIRONMENT