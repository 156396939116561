export const RESTART_ON_REMOUNT = '@@saga-injector/restart-on-remount'
export const DAEMON = '@@saga-injector/daemon'
export const ONCE_TILL_UNMOUNT = '@@saga-injector/once-till-unmount'
export const MOBILE_WEBVIEW_ACCESS = 'mobile_webview_access' // to determine if mobile webview

export const PAYMENT_TYPE = {
  CC: 'Credit_Card',
  ECP: 'ECP',
}

export const ADD_FUNDS_MODE = {
  EXISTING_METHOD: 'ADD_FUNDS_MODE_EXISTING_METHOD',
  NEW_METHOD: 'ADD_FUNDS_MODE_NEW_METHOD',
}

export const ACCOUNT_TYPE = {
  savings: 'Savings',
  checking: 'Checking',
  commercialChecking: 'Commercial Checking',
}

export const MOBILE_WEBVIEW_EVENT = {
  languageChange: 'languageChange',
  memberChange: 'memberChange',
}

export const useNewPaymentMethod = {
  CardID: 'useNewPaymentMethod',
  cardType: 'useNewPaymentMethodPlaceholder',
}
