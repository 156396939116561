import React from 'react'
import { Link } from 'react-router-dom'
import { Trans } from '@lingui/macro'

import Error from 'components/Form/ErrorMessage'

const ErrorTaken = () => (
  <Error>
    <Trans>
      This email is already registered. Want to{' '}
      <Link data-testid="loginLink1" to="/login">
        login
      </Link>{' '}
      or{' '}
      <Link data-testid="forgotPasswordLink" to="/forgot-password">
        recover your password
      </Link>
      ?
    </Trans>
  </Error>
)

export default ErrorTaken
