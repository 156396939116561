import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

import checkmark from 'assets/images/icons/checkmark.svg'
import arrowBottom from 'assets/images/icons/arrow-bottom.svg'
import { zIndex, easing, color } from 'styles/variables'
import { breakpoints } from 'styles/mq'

export const MeterDropdown = styled.div`
  position: relative;
  border-bottom: 1px solid ${color.gray};
  padding: 8px 0;
`
export const MeterDropdownItem = styled.div`
  padding: 0 15px 13px;
`

export const ListSpacer = styled.hr`
  border-top: 1px solid ${color.gray};
  border-bottom-width: 0;
  margin: 0 0 15px 0;
`

export const MeterList = styled.div``

export const Arrow = styled.div`
  width: 12px;
  height: 8px;
  background-image: url(${arrowBottom});
  background-size: contain;
`

export const ToggleButton = styled.button`
  position: absolute;
  top: 40px;
  right: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  transition: transform 0.3s ease;
  transform: translateY(-50%)
    rotate(${props => (props.isMeterListOpen ? 180 : 0)}deg);
`

export const NavList = styled.ul`
  margin: 0;
  padding: 8px 0;
`

export const ListItem = styled.li`
  &:last-child {
    padding: 15px;
  }
`

export const Link = styled(NavLink)`
  &.selected {
    & > div {
      position: relative;
      ::after {
        content: '';
        position: absolute;
        top: calc(50% - 10px);
        right: 15px;
        width: 18px;
        height: 18px;
        background: url(${checkmark}) no-repeat center center;
      }
    }
  }
`
export const StyledLink = styled(NavLink)`
  position: relative;
  display: flex;
  align-items: center;
  padding: 12px 15px;
  color: #7d8d9e;
  font-size: 1.6rem;
  font-weight: 500;
  text-decoration: none;
  transition: all 0.3s ease;

  &.selected {
    color: ${color.primary};
    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 4px;
      background: ${color.primary};
    }
    div {
      color: ${color.primary};
    }
  }

  svg {
    flex-shrink: 0;
  }
`
export const StyledItem = styled.div`
  display: flex;
`

export const StyledNav = styled.nav`
  display: none;

  @media (max-width: ${breakpoints.tablet - 1}px) {
    display: block;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    overflow: auto;
    padding-top: ${props =>
      props.paddingTop ? `${props.paddingTop + 60}px` : '60px'};
    background: #fff;
    z-index: ${zIndex.nav};
    transform: translateY(-100%);
    transition: 0.2s transform ${easing.easeInOutCubic};
    ${props => props.isOpen && 'transform: translateY(0)'};
  }
`
