import styled from 'styled-components'
import {
  fontSize,
  space,
  color,
  textAlign,
  flex,
  alignSelf,
  lineHeight,
  width,
  maxWidth,
  fontWeight,
} from 'styled-system'

import { color as colors, font } from 'styles/variables'
import mq from 'styles/mq'

import { linkStyles } from 'components/buttons/LinkButton/styled'

const defaultFontSize = props => {
  if (props.large) return '1.8rem'
  if (props.small) return '1.4rem'
  if (props.extraSmall) return '1.2rem'
  return '1.6rem'
}

export const Text = styled.div`
  font-family: ${font.customFamily};
  font-size: ${defaultFontSize};
  color: ${colors.base};
  font-weight: ${props => (props.medium ? font.medium : font.normal)};
  ${props =>
    props.ellipsis &&
    `
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    `};
  ${fontSize};
  ${space};
  ${color};
  ${textAlign};
  ${flex};
  ${alignSelf};
  ${lineHeight};
  ${width};
  ${maxWidth};
  ${fontWeight}
  
  & strong {
    font-weight: ${font.semiBold};
  }

  & a {
    ${linkStyles};
  }
`

export const TextHighlight = styled(Text)`
  display: inline-block;
  color: ${colors.primary};
  font-weight: ${font.medium};
`

export const TextBold = styled(Text)`
  font-weight: ${font.extraBold};
  font-size: 1.8rem;
  ${fontSize};
`

export const TextSemi = styled(Text)`
  font-weight: ${font.semiBold};
  font-size: 1.8rem;
  ${fontSize};
`

export const Heading = styled(Text)`
  font-weight: ${font.semiBold};
  font-size: 2.2rem;
  ${mq.tablet`
    font-size: 3rem;
  `};
  ${textAlign}
`

export const HeadingBold = styled(TextBold)`
  font-size: 2.4rem;
`

export const TextSecondary = styled(Text)`
  color: ${colors.neutral3};
  font-weight: ${font.normal};
`

export const TextSecondarySummary = styled(Text)`
  color: ${colors.neutral3};
  font-weight: ${font.normal};
  margin-top: 4px;
`
