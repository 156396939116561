import React from 'react'
import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'

import { logoConfig } from 'config/profile'

import { zIndex, color } from 'styles/variables'
import mq from 'styles/mq'

const getLogo = () => {
  if (!logoConfig.useLocal) {
    return logoConfig.url
  }

  return logoConfig.logo
}

export const StyledHeader = styled.header`
  z-index: ${zIndex.header};
`
export const HeaderBackground = styled.div`
  padding: 0 15px;
  height: 60px;
  display: ${props => (props?.hide ? 'none' : 'flex')};
  align-items: center;
  justify-content: space-between;
  background: ${color.gradient};
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  transition: height 0.3s ease;

  ${mq.tablet`
    padding: 0 60px;
    height: 98px;
  `};
`

export const StyledLogo = () => {
  return (
    <StyledLogoWrapper>
      <Logo />
    </StyledLogoWrapper>
  )
}

const StyledLogoWrapper = logoConfig.wrapper
  ? styled.div`
      background-color: ${logoConfig.wrapper.backgroundColor};

      ${mq.phone`
        padding: 0px;
      `};
      ${mq.tablet`
      padding: ${logoConfig.wrapper.padding}px;
      `};
      ${mq.desktop`
      padding: ${logoConfig.wrapper.padding}px;
      `};
    `
  : styled.div``

const Logo =
  logoConfig.imgType === 'png'
    ? styled.img.attrs({
        src: getLogo(),
      })`
        position: relative;
        top: 1px;
        width: ${logoConfig.width};
        height: ${logoConfig.height};

        ${mq.tablet`
    top: 3px;
    width: ${logoConfig.width};
    height: ${logoConfig.height};
  `};
      `
    : styled(logoConfig.logo)`
        position: relative;
        top: 1px;
        width: ${logoConfig.width};
        height: ${logoConfig.height};

        ${mq.tablet`
    top: 3px;
    width: 235px;
    height: 45px;
  `};
      `

export const EnrollButton = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 28px;
  height: 48px;
  border: 2px solid white;
  border-radius: 24px;

  margin-right: 40px;

  color: white;
  font-size: 18px;
  font-weight: 600;
`
export const NotificationButton = styled.button`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  border-radius: 50%;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
  transition: background-color 0.3s ease;

  ${props =>
    props.inboxOpen &&
    css`
      background-color: rgba(255, 255, 255, 0.2);
    `}

  ${mq.tablet`
    margin-left: 12px;
    margin-right: -32px;
  
    &:enabled:hover {
      background-color: rgba(255, 255, 255, 0.2);
    }
    &:disabled {
      cursor: default;
    }
  `};
`

export const StyledWrapper = styled.div`
  transition: opacity 200ms;
  opacity: 1;
  &.exit-active {
    opacity: 0;
    transition: opacity 200ms;
  }
`
