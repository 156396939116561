import { where, is } from 'ramda'
import invariant from 'invariant'

/**
 * Validate the shape of redux store
 */
export default function checkStore(store) {
  const validateShape = where({
    dispatch: is(Function),
    subscribe: is(Function),
    getState: is(Function),
    replaceReducer: is(Function),
    runSaga: is(Function),
    injectedSagas: is(Object),
  })

  invariant(
    validateShape(store),
    '(app/utils...) injectors: Expected a valid redux store'
  )
}
