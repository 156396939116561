import React from 'react'

const Bell = () => (
  <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.455 11.818V7.273a5.455 5.455 0 00-10.91 0v4.545a3.62 3.62 0 01-.486 1.818h11.882a3.62 3.62 0 01-.486-1.818zm3.636 3.637H.909c-1.212 0-1.212-1.819 0-1.819a1.818 1.818 0 001.818-1.818V7.273a7.273 7.273 0 0114.546 0v4.545c0 1.004.814 1.818 1.818 1.818 1.212 0 1.212 1.819 0 1.819zm-6.732 3.183a2.727 2.727 0 01-4.718 0 .91.91 0 01.786-1.365h3.146c.7 0 1.138.759.786 1.365z"
      fill="#FFF"
      fillRule="nonzero"
    />
  </svg>
)

export default Bell
