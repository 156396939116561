import { merge } from 'ramda'

import {
  GET_COSTS_REQUEST,
  GET_COSTS_SUCCESS,
  GET_WEATHER_SUCCESS,
} from './constants'

export const initialState = {
  now: {},
  forecast: [],
  weather: {},
  refreshAt: null,
  loading: true,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_COSTS_REQUEST: {
      return merge(state, { loading: true })
    }

    case GET_COSTS_SUCCESS: {
      const { now, forecast, refreshAt } = action.payload
      return merge(state, {
        now,
        forecast,
        refreshAt,
        loading: false,
      })
    }

    case GET_WEATHER_SUCCESS: {
      return merge(state, { weather: action.payload, loading: false })
    }

    default:
      return state
  }
}

export default reducer
