import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'
import { createStructuredSelector } from 'reselect'

import { selectIsAuthenticated } from 'modules/user/selectors'

export const PrivateRoute = ({ isAuthenticated, ...rest }) =>
  isAuthenticated ? <Route {...rest} /> : <Redirect to="/login" />

PrivateRoute.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
}

const mapStateToProps = createStructuredSelector({
  isAuthenticated: selectIsAuthenticated,
})

export default connect(mapStateToProps)(PrivateRoute)
