import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { DateTime } from 'luxon'

import { selectLocale } from 'modules/language/selectors'
import { selectTimeZone } from 'modules/members/selectors'

const defaultTimeFormat = 'yyyy-LL-dd'

const mapState = createStructuredSelector({
  locale: selectLocale,
  timeZone: selectTimeZone,
})

export const localizeDate = ({
  date,
  format = defaultTimeFormat,
  locale = 'en',
  timeZone = 'local',
}) =>
  DateTime.fromISO(date)
    .setZone(timeZone)
    .setLocale(locale)
    .toFormat(format)

export default connect(mapState)(localizeDate)
