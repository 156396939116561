import { merge } from 'ramda'

import {
  SET_TIME_INTERVAL,
  GET_HISTORY_REQUEST,
  GET_HISTORY_SUCCESS,
  GET_HISTORY_FAILURE,
  INTERVALS,
} from './constants'
import { sortIntervals } from './helpers'

export const initialState = {
  history: {
    hourly: [],
    daily: [],
    monthly: [],
  },
  interval: sortIntervals(INTERVALS)[0].value,
  isLoading: false,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TIME_INTERVAL: {
      return merge(state, { interval: action.interval })
    }

    case GET_HISTORY_REQUEST: {
      return merge(state, { isLoading: true })
    }
    case GET_HISTORY_SUCCESS: {
      return merge(state, {
        meterID: action.meterID,
        history: action.payload,
        isLoading: false,
      })
    }
    case GET_HISTORY_FAILURE: {
      return initialState
    }

    default:
      return state
  }
}

export default reducer
