import { breakpoints } from './mq'
import { color } from './variables'

/**
 * Breakpoints used in styled-system & grid-styled correspond to ours
 */
export default {
  breakpoints: Object.keys(breakpoints)
    .filter(key => key !== 'phone' && key !== 'smallPhone')
    .map(key => `${breakpoints[key]}px`),
  colors: color,
}
