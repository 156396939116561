import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Trans } from '@lingui/macro'

import Modal from 'components/Modal'
import Loader from 'components/Loader'
import CheckIcon from 'assets/images/icons/checkmark_green.svg'
import { input, color } from 'styles/variables'
import { TextSemi } from 'components/text'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 240px;
  justify-content: center;
  align-items: center;
`
const StyledCheckbox = styled.div`
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 1px solid ${input.borderColor};
  background: url(${CheckIcon}) no-repeat center center;
`

const SwitchMeterModal = ({ switchMeter: { isSwitching, nextMeterNum } }) => (
  <Modal>
    {isSwitching ? (
      <Wrapper>
        <TextSemi mb={20}>
          <Trans>Switching to Meter {nextMeterNum}</Trans>
        </TextSemi>
        <Loader color={color.primary} />
      </Wrapper>
    ) : (
      <Wrapper>
        <TextSemi mb={11}>
          <Trans>Switched!</Trans>
        </TextSemi>
        <StyledCheckbox />
      </Wrapper>
    )}
  </Modal>
)

SwitchMeterModal.propTypes = {
  switchMeter: PropTypes.object,
}

export default SwitchMeterModal
