// Zendesk widget throws unreasonably when called two times in a short time
let open = true

const zendesk = {
  show() {
    if (!open) {
      window.zE.show ? window.zE.show() : window.zE(() => window.zE.show())
      open = true
    }
  },
  hide() {
    if (open) {
      window.zE.hide ? window.zE.hide() : window.zE(() => window.zE.hide())
      open = false
    }
  },
}

zendesk.hide()

export default zendesk
