import styled from 'styled-components'

import mq from 'styles/mq'
import { sizes } from 'styles/variables'

export const Dashboard = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  overflow: hidden;
  z-index: 0;
`

export const Main = styled.main`
  position: relative;
  overflow: auto;
  overflow-y: overlay;
  flex: 1;
  display: flex;
  flex-direction: column;
`
const {
  mainContainer: { padding },
} = sizes
const padBy = media => {
  return `${padding[media].top}px ${padding[media].side}px ${
    padding[media].bottom
  }px`
}

export const MainContainer = styled.div`
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  padding: ${props => (props.isWebView ? '0' : padBy('mobile'))};

  ${mq.tablet`
  	padding: ${props => (props.isWebView ? '0' : padBy('tablet'))};
  `};

  ${mq.desktop`
    padding: ${padBy('desktop')};
  `};
`
