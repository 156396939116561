import styled, { keyframes } from 'styled-components'

import { color as colors } from 'styles/variables'

const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: scale(0.95);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
`

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(95, 96, 118, 0.03);
  padding: 0 10px;
  backdrop-filter: blur(5px);
  transition: all 0.3s ease-in;
  animation: ${fadeIn} 0.1s ease-in forwards; /* Apply the animation */
`

export const WhiteBox = styled.div`
  background: #fff;
  border-radius: 4px;
  padding: 20px 30px;
  border: 1px solid #d5dee5;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.3);
  max-width: 530px;
`

export const CloseButton = styled.button`
  cursor: pointer;
  padding: 0;
  color: ${colors.secondary};

  &:hover {
    color: #000;
  }
`
