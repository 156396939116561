export const GET_RENEWAL_OFFER_REQUEST = 'renewal_offer/RENEWAL_OFFER_REQUEST'
export const RENEWAL_OFFER_FAILURE = 'renewal_offer/RENEWAL_OFFER_FAILURE'
export const RENEWAL_OFFER_SUCCESS = 'renewal_offer/RENEWAL_OFFER_SUCCESS'

export const SELECT_OFFER = 'renewal_offer/SELECT_OFFER'
export const SUBMIT_RENEWAL_OFFER_FORM = 'renewal/SUBMIT_RENEWAL_OFFER_FORM'
export const SUBMIT_RENEWAL_OFFER_FORM_SUCCESS =
  'renewal/SUBMIT_RENEWAL_OFFER_FORM_SUCCESS'
export const SUBMIT_RENEWAL_OFFER_FORM_FAILURE =
  'renewal/SUBMIT_RENEWAL_OFFER_FORM_FAILURE'

export const HIDE_RENEWAL_PAGE = 'renewal/HIDE_RENEWAL_PAGE'
export const CLEAR_RENEWAL_ENROLLMENT_DATA =
  'renewal/CLEAR_RENEWAL_ENROLLMENT_DATA'
