export const LOAD_REQUEST = 'addMeter/LOAD_REQUEST'
export const LOAD_SUCCESS = 'addMeter/LOAD_SUCCESS'
export const INIT_FORM = 'addMeter/INIT_FORM'

export const METER_LOOKUP_REQUEST = 'addMeter/METER_LOOKUP_REQUEST'
export const METER_LOOKUP_FAILURE = 'addMeter/METER_LOOKUP_FAILURE'
export const METER_LOOKUP_SUCCESS = 'addMeter/METER_LOOKUP_SUCCESS'

export const SAVE_SERVICE_ADDRESS_VALUES =
  'addMeter/SAVE_SERVICE_ADDRESS_VALUES'
export const SUBMIT_SERVICE_ADDRESS_FORM =
  'addMeter/SUBMIT_SERVICE_ADDRESS_FORM'

export const SAVE_SELECT_ADDRESS_VALUES = 'addMeter/SAVE_SELECT_ADDRESS_VALUES'
export const SUBMIT_SELECT_ADDRESS_FORM = 'addMeter/SUBMIT_SELECT_ADDRESS_FORM'

export const SAVE_SERVICE_INFO_VALUES = 'addMeter/SAVE_SERVICE_INFO_VALUES'
export const SUBMIT_SERVICE_INFO_FORM = 'addMeter/SUBMIT_SERVICE_INFO_FORM'

export const SUBMIT_PAYMENT_FORM = 'addMeter/SUBMIT_PAYMENT_FORM'

export const MM_ENROLL_REQUEST = 'addMeter/MM_ENROLL_REQUEST'
export const MM_ENROLL_FAILURE = 'addMeter/MM_ENROLL_FAILURE'
export const MM_ENROLL_SUCCESS = 'addMeter/MM_ENROLL_SUCCESS'

export const ADD_CARD_FAILURE = 'addMeter/ADD_CARD_FAILURE'
export const ADD_CARD_SUCCESS = 'addMeter/ADD_CARD_SUCCESS'

export const ADD_FUNDS_FAILURE = 'addMeter/ADD_FUNDS_FAILURE'
export const ADD_FUNDS_SUCCESS = 'addMeter/ADD_FUNDS_SUCCESS'

export const ADD_METER_FAILURE = 'addMeter/ADD_METER_FAILURE'
export const ADD_METER_SUCCESS = 'addMeter/ADD_METER_SUCCESS'

export const SAVE_START_DATE = 'addMeter/SAVE_START_DATE'
export const SET_SHOULD_REDIRECT_TO_PAYMENT =
  'addMeter/SET_SHOULD_REDIRECT_TO_PAYMENT'
