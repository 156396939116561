export const LOGIN_REQUEST = 'user/LOGIN_REQUEST'
export const LOGIN_FAILURE = 'user/LOGIN_FAILURE'
export const LOGIN_SUCCESS = 'user/LOGIN_SUCCESS'

export const CREATE_USER_REQUEST = 'user/CREATE_USER_REQUEST'
export const CREATE_USER_FAILURE = 'user/CREATE_USER_FAILURE'
export const CREATE_USER_SUCCESS = 'user/CREATE_USER_SUCCESS'

export const GET_USER_REQUEST = 'user/GET_USER_REQUEST'
export const GET_USER_FAILURE = 'user/GET_USER_FAILURE'
export const GET_USER_SUCCESS = 'user/GET_USER_SUCCESS'

export const UPDATE_USER_REQUEST = 'user/UPDATE_USER_REQUEST'
export const UPDATE_USER_FAILURE = 'user/UPDATE_USER_FAILURE'
export const UPDATE_USER_SUCCESS = 'user/UPDATE_USER_SUCCESS'

export const SUBMIT_LOGIN_FORM = 'user/SUBMIT_LOGIN_FORM'
export const SUBMIT_SIGNUP_FORM = 'user/SUBMIT_SIGNUP_FORM'

export const SAVE_FORM_EMAIL = 'user/SAVE_FORM_EMAIL'
