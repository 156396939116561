import { merge } from 'ramda'
import {
  GET_RENEWAL_OFFER_REQUEST,
  RENEWAL_OFFER_SUCCESS,
  RENEWAL_OFFER_FAILURE,
  SELECT_OFFER,
  SUBMIT_RENEWAL_OFFER_FORM,
  SUBMIT_RENEWAL_OFFER_FORM_SUCCESS,
  SUBMIT_RENEWAL_OFFER_FORM_FAILURE,
  HIDE_RENEWAL_PAGE,
  CLEAR_RENEWAL_ENROLLMENT_DATA,
} from './constants'

const ENROLLMENT_TYPE_RENEWAL = 'renewal'
export const initialState = {
  error: '',
  loading: false,
  offers: [],
  enrollment: {
    enrollmentType: ENROLLMENT_TYPE_RENEWAL,
    createdBy: 'customer',
    renewalDate: '',
    formData: {
      memberID: '',
      product: {},
      TOSYRACConfirm: false,
      EFLConfirm: false,
    },
  },
  enrollmentID: '',
  verificationRequired: '',
  verificationStatus: '',
  status: '',
  credit_file_data: {},
  hideRenewalPage: false,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_RENEWAL_ENROLLMENT_DATA: {
      return merge(state, {
        enrollment: initialState.enrollment,
      })
    }
    case HIDE_RENEWAL_PAGE:
      return merge(state, {
        hideRenewalPage: action.payload,
      })
    case GET_RENEWAL_OFFER_REQUEST:
      return merge(state, {
        loading: true,
      })
    case RENEWAL_OFFER_SUCCESS: {
      const { renewal_offers, renewal_date } = action.payload
      return merge(state, {
        enrollment: {
          ...state.enrollment,
          renewalDate: renewal_date,
        },
        offers: renewal_offers,
        loading: false,
      })
    }
    case RENEWAL_OFFER_FAILURE:
    case SUBMIT_RENEWAL_OFFER_FORM_FAILURE:
      return merge(state, { error: action.payload, loading: false })
    case SELECT_OFFER: {
      return merge(state, {
        enrollment: {
          ...state.enrollment,
          formData: {
            ...state.enrollment.formData,
            product: action.payload,
          },
        },
      })
    }
    case SUBMIT_RENEWAL_OFFER_FORM: {
      const { TOSYRACEFLConfirm } = action.payload
      return merge(state, {
        enrollment: {
          ...state.enrollment,
          formData: {
            ...state.enrollment.formData,
            TOSYRACConfirm: TOSYRACEFLConfirm,
            EFLConfirm: TOSYRACEFLConfirm,
          },
        },
        loading: true,
      })
    }
    case SUBMIT_RENEWAL_OFFER_FORM_SUCCESS: {
      return merge(state, {
        ...action.payload,
      })
    }
    default:
      return state
  }
}

export default reducer
