import { merge, pathOr } from 'ramda'

import * as C from './constants'
import * as C_MEMBERS from 'modules/members/constants'

export const initialState = {
  meterID: '',
  memberID: '',
  priceLockRate: {},
  eligibleUnlockDate: '',
  summerAutoLock: false,
  priceLockStatus: 'unlocked',
  loading: false,
  errorMessage: '',
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case C.GET_PRICE_LOCK_RATE:
    case C.SET_PRICE_LOCK_REQUEST:
    case C_MEMBERS.GET_METER_REQUEST: {
      return merge(state, { loading: true })
    }

    case C.GET_PRICE_LOCK_RATE_SUCCESS: {
      return merge(state, {
        priceLockRate: action.payload,
        loading: false,
        errorMessage: null,
      })
    }

    case C.SET_PRICE_LOCK_REQUEST_SUCCESS: {
      return merge(state, { loading: false, errorMessage: null })
    }

    case C_MEMBERS.GET_METER_SUCCESS: {
      const nextState = {
        summerAutoLock: pathOr('', ['payload', 'summer_auto_lock'], action),
        eligibleUnlockDate: pathOr(
          '',
          ['payload', 'eligible_unlock_date'],
          action
        ),
        priceLockStatus: pathOr('', ['payload', 'price_lock_status'], action),
        loading: false,
      }
      return merge(state, nextState)
    }

    case C.UPDATE_AUTO_SUMMER_LOCK_SUCCESS: {
      return merge(state, {
        summerAutoLock: action.payload,
        loading: false,
        errorMessage: null,
      })
    }

    case C.UPDATE_AUTO_SUMMER_LOCK_FAILURE:
    case C.SET_PRICE_LOCK_REQUEST_FAILURE:
    case C.GET_PRICE_LOCK_RATE_FAILURE:
    case C_MEMBERS.GET_METER_FAILURE: {
      let msg = action.payload
      if (typeof msg !== 'string') {
        msg = 'Request failed. Please try again later'
      }
      return merge(state, { errorMessage: msg, loading: false })
    }

    case C.SET_IDS: {
      const { meterID, memberID } = action.payload
      return merge(state, { meterID, memberID })
    }

    default:
      return state
  }
}

export default reducer
