import styled from 'styled-components'
import mq from 'styles/mq'

export const ImpersonateSection = styled.div`
  background: red;
  padding: 10px 20px;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  ${mq.tablet`
    padding: 10px 20px;
    flex-direction: column;
  `};

  &:last-of-type {
    margin-bottom: 0;
  }
`

export const ImpersonateSectionDescription = styled.div`
  /* background: #fff; */
  display: flex;
  flex: 0.65;
  flex-direction: column;
  justify-content: space-around;
`

export const ImpersonateDescription = styled.span`
  font-size: 1.6rem;
  text-align: justify;
  align-self: center;
  ${mq.tablet`
    display: flex;
  `};
`
