export const GET_ACCOUNT_REQUEST = 'account/GET_ACCOUNT_REQUEST'
export const GET_ACCOUNT_SUCCESS = 'account/GET_ACCOUNT_SUCCESS'
export const GET_ACCOUNT_FAILURE = 'account/GET_ACCOUNT_FAILURE'

export const GET_ACCOUNT_BALANCE_SUCCESS = 'account/GET_ACCOUNT_BALANCE_SUCCESS'
export const GET_ACCOUNT_BALANCE_FAILURE = 'account/GET_ACCOUNT_BALANCE_FAILURE'

export const GET_ACCOUNT_STATEMENT_SUCCESS = 'account/GET_ACCOUNT_STATEMENT_SUCCESS'
export const GET_ACCOUNT_STATEMENT_FAILURE = 'account/GET_ACCOUNT_STATEMENT_FAILURE'

export const CLEAR_ACCOUNT_ERROR = 'account/CLEAR_ACCOUNT_ERROR'

export const HIDE_CHANGE_AUTO_PAY_MODAL = 'account/HIDE_CHANGE_AUTO_PAY_MODAL'
export const SHOW_CHANGE_AUTO_PAY_MODAL = 'account/SHOW_CHANGE_AUTO_PAY_MODAL'
export const UPDATE_BILLING_AUTO_PAY_REQUEST =
  'account/UPDATE_BILLING_AUTO_PAY_REQUEST'
export const SUBMIT_UPDATE_AUTO_PAY_SUCCESS =
  'account/SUBMIT_UPDATE_AUTO_PAY_SUCCESS'
export const SUBMIT_UPDATE_AUTO_PAY_FAILURE =
  'account/SUBMIT_UPDATE_AUTO_PAY_FAILURE'
export const SUBMIT_UPDATE_AUTO_PAY = 'account/SUBMIT_UPDATE_AUTO_PAY'

export const UPDATE_BILLING_STATEMENT_DELIVERY_REQUEST =
  'account/UPDATE_BILLING_STATEMENT_DELIVERY_REQUEST'
export const SUBMIT_UPDATE_STATEMENT_DELIVERY_SUCCESS =
  'account/SUBMIT_UPDATE_STATEMENT_DELIVERY_SUCCESS'
export const SUBMIT_UPDATE_STATEMENT_DELIVERY_FAILURE =
  'account/SUBMIT_UPDATE_STATEMENT_DELIVERY_FAILURE'
export const SUBMIT_UPDATE_STATEMENT_DELIVERY =
  'account/SUBMIT_UPDATE_STATEMENT_DELIVERY'

export const SUBMIT_NEW_BILLING_ADDRESS_FORM =
  'account/SUBMIT_NEW_BILLING_ADDRESS_FORM'
export const SUBMIT_NEW_EMAIL_FORM = 'account/SUBMIT_NEW_EMAIL_FORM'
export const SUBMIT_NEW_PASSWORD_FORM = 'account/SUBMIT_NEW_PASSWORD_FORM'
export const SUBMIT_NEW_PHONE_FORM = 'account/SUBMIT_NEW_PHONE_FORM'
export const SUBMIT_LANGUAGE_PREFERENCE = 'account/SUBMIT_LANGUAGE_PREFERENCE'
export const SUBMIT_RECHARGE_FORM = 'account/SUBMIT_RECHARGE_FORM'
export const SUBMIT_ADD_CARD_FORM = 'account/SUBMIT_ADD_CARD_FORM'
export const DELETE_CARD = 'account/DELETE_CARD'
export const SET_PRIMARY_CARD = 'account/SET_PRIMARY_CARD'

export const GET_CARDS_SUCCESS = 'account/GET_CARDS_SUCCESS'
export const ADD_CARD_FAILURE = 'accoutn/ADD_CARD_FAILURE'
export const ADD_CARD_SUCCESS = 'account/ADD_CARD_SUCCESS'

export const DELETE_CARD_SUCCESS = 'account/DELETE_CARD_SUCCESS'
export const SET_PRIMARY_CARD_SUCCESS = 'account/UPDATE_CARD_SUCCESS'

export const UPDATE_USER_REQUEST = 'account/UPDATE_USER_REQUEST'
export const UPDATE_USER_SUCCESS = 'account/UPDATE_USER_SUCCESS'
export const UPDATE_USER_FAILURE = 'account/UPDATE_USER_FAILURE'

export const SUBMIT_ADD_FUNDS_FORM = 'account/SUBMIT_ADD_FUNDS_FORM'
export const ADD_FUNDS_SUCCESS = 'account/ADD_FUNDS_SUCCESS'
export const ADD_FUNDS_FAILURE = 'account/ADD_FUNDS_FAILURE'

export const SHOW_SUBMIT_SUCCESS_MODAL = 'account/SHOW_SUBMIT_SUCCESS_MODAL'
export const HIDE_SUBMIT_SUCCESS_MODAL = 'account/HIDE_SUBMIT_SUCCESS_MODAL'

export const GET_DEPOSITS = 'account/GET_DEPOSITS'
export const CLEAR_DEPOSITS = 'account/CLEAR_DEPOSITS'
export const RESET_DEPOSITS = 'account/RESET_DEPOSITS'
export const GET_DEPOSITS_REQUEST = 'account/GET_DEPOSITS_REQUEST'
export const GET_DEPOSITS_SUCCESS = 'account/GET_DEPOSITS_SUCCESS'
export const GET_DEPOSITS_FAILURE = 'account/GET_DEPOSITS_FAILURE'

export const HAS_MORE_DEPOSITS = 'account/HAS_MORE_DEPOSITS'
export const DEPOSITS_PAGINATION_COMPLETE =
  'account/DEPOSITS_PAGINATION_COMPLETE'

export const GET_PRODUCTS = 'account/GET_PRODUCTS'
export const GET_PRODUCTS_REQUEST = 'account/GET_PRODUCTS_REQUEST'
export const GET_PRODUCTS_SUCCESS = 'account/GET_PRODUCTS_SUCCESS'
export const GET_PRODUCTS_FAILTURE = 'account/GET_PRODUCTS_FAILTURE'

export const SUBMIT_CHASE_PAYMENT_CUSTOM_FORM =
  'signup/SUBMIT_CHASE_PAYMENT_CUSTOM_FORM'
export const SUBMIT_ICHECK_PAYMENT_CUSTOM_FORM =
  'signup/SUBMIT_ICHECK_PAYMENT_CUSTOM_FORM'
export const SUBMIT_CHASE_PAYMENT_CUSTOM_FORM_SUCCESS =
  'signup/SUBMIT_CHASE_PAYMENT_CUSTOM_FORM_SUCCESS'
export const SUBMIT_CHASE_PAYMENT_CUSTOM_FORM_FAILURE =
  'signup/SUBMIT_CHASE_PAYMENT_CUSTOM_FORM_FAILURE'

export const LOAD_ACCOUNT_PAGE = 'account/LOAD_ACCOUNT_PAGE'

export const CLEAR_NET_METERING_HISTORY = 'account/CLEAR_NET_METERING_HISTORY'
export const GET_NET_METERING_HISTORY = 'account/GET_NET_METERING_HISTORY'
export const GET_NET_METERING_HISTORY_SUCCESS = 'account/GET_NET_METERING_HISTORY_SUCCESS'
export const GET_NET_METERING_HISTORY_FAILURE = 'account/GET_NET_METERING_HISTORY_FAILURE'
