import * as C from './constants'

export const setStatementDate = date => ({
  type: C.SET_STATEMENT_DATE,
  date,
})

export const selectStatementDate = date => ({
  type: C.SELECT_STATEMENT_DATE,
  date,
})

export const getStatementRequest = () => ({
  type: C.GET_STATEMENT_REQUEST,
})
export const getStatementSuccess = payload => ({
  type: C.GET_STATEMENT_SUCCESS,
  payload,
})
export const getStatementFailure = payload => ({
  type: C.GET_STATEMENT_FAILURE,
  payload,
})

export const loadStatementPage = payload => ({
  type: C.LOAD_STATEMENT_PAGE,
  payload,
})
