import React from 'react'

const AlertBell = () => (
  <svg width="22" height="22" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.573 19.273c.7 0 1.138.759.786 1.365a2.727 2.727 0 01-4.718 0 .91.91 0 01.786-1.365h3.146zM10 2c.222 0 .442.01.66.03a6.961 6.961 0 00-.561 1.79 5.455 5.455 0 00-5.554 5.452v4.546a3.62 3.62 0 01-.486 1.818h11.882a3.62 3.62 0 01-.486-1.818v-1.99a7.024 7.024 0 001.818.167v1.823c0 1.004.814 1.818 1.818 1.818 1.212 0 1.212 1.819 0 1.819H.909c-1.212 0-1.212-1.819 0-1.819a1.818 1.818 0 001.818-1.818V9.273A7.273 7.273 0 0110 2zm7.5-2a4.5 4.5 0 110 9 4.5 4.5 0 010-9z"
      fill="#FFF"
      fillRule="nonzero"
    />
  </svg>
)

export default AlertBell
