export const disableAlignment = false

export const hasAccessToUsageV2 = userEmail => {
  const whiteList = [
    'dev-20001@griddyx.com',
    'dev-20002@griddyx.com',
    'dev-20003@griddyx.com',
    'dev-20004@griddyx.com',
    'dev-20005@griddyx.com',
    'dev-20006@griddyx.com',
    'dev-20007@griddyx.com',
    'dev-35001@griddyx.com',
    'dev-35002@griddyx.com',
    'dev-35003@griddyx.com',
    'dev-35004@griddyx.com',
    'dev-35005@griddyx.com',
    'dev-35006@griddyx.com',
    'dev-34485@griddyx.com',
    'dev-1@griddyx.com',
    'dev-2@griddyx.com',
    'dev-3@griddyx.com',
    'dev-4@griddyx.com',
    'dev-5@griddyx.com',
    'dev-6@griddyx.com',
    'dev-7@griddyx.com',
    'dev-8@griddyx.com',
    'dev-9@griddyx.com',
    'dev-10@griddyx.com',
    'dev-11@griddyx.com',
    'dev-12@griddyx.com',
    'guest@gg.com',
    'mfriede@gmail.com',
    'mlantier@gmail.com',
    'daveoakland@gmail.com',
    'eavina5@gmail.com',
    'leonard.clayton3374@gmail.com',
    'minicer@gmail.com',
    'tj.dailey@yahoo.com',
    'Ericolsonlmt@gmail.com',
    'patricia.buchanan@outlook.com',
    'olelucoelle@gmail.com',
    'jvak@outlook.com',
    'dan3546@aol.com',
    'sandy091673@aol.com',
    'masonranch@gmail.com',
    'mattdittman@gmail.com',
    'cwgay@ymail.com',
    'baknsaddle@yahoo.com',
    'aleciacreyes@gmail.com',
    'kennlong@gmail.com',
    'psgaribay@me.com',
    'susanw0317@yahoo.com',
    'keenan.jones85@yahoo.com',
    'ralphmoorerem@att.net',
    'maripalacios316@gmail.com',
    'MARK.STORER@USA.NET',
    'pat@patwood.net',
    'melanielegg09@comcast.net',
    'treypraytor@gmail.com',
    'don.arsenych@verizon.net',
    'john.loth@ieee.org',
    'raul.arellano17@icloud.com',
    'cdbasden2@gmail.com',
    'stanley.chuck@gmail.com',
    'greglucemusic@gmail.com',
    'susan_fargason@yahoo.com',
    'rayyellig@gmail.com',
    'cpostert703@yahoo.com',
    'rjdelacr@protonmail.com',
    'kmoses_84@yahoo.com',
    'monkeywrench01@gmail.com',
    'josh.green.tx@gmail.com',
    'rzipter@aol.com',
    'csoakley@gmail.com',
    'cordlargo@gmail.com',
    'lysacor@gmail.com',
    'bburlette@gmail.com',
    'wbbrumfield@gmail.com',
    'brianm701@gmail.com',
    'edgarklvt@gmail.com',
    'stx7319@gmail.com',
    'will.chris@gmail.com',
    'g2@gbkelly.com',
    'mesc41@yahoo.com',
    'kyleenblanchard@gmail.com',
    'ascarid68@gmail.com',
    'catholcken@gmail.com',
    'kmheim1@gmail.com',
    'srsanders@gmail.com',
    'mtaylor587@gmail.com',
    'jdavidbowman@hotmail.com',
    'KWTietz@att.net',
    'ksculbert@gmail.com',
    'texbiker@texbiker.net',
    'ray@bigonesse.com',
    'r1666a@gmail.com',
    'jayburg@gmail.com',
    'rdavescott@gmail.com',
    'DUSTY_0211@ATT.NET',
    'patrickodneal@gmail.com',
    'cory.imdieke@gmail.com',
    'jkggarr@gmail.com',
    'RunWithMe35@gmail.com',
    'breedijk.t@gmail.com',
    'evan.dozier@gmail.com',
    'kerby@jfkerby.com',
    'mdhanerawala@gmail.com',
    'downerw@gmail.com',
    'clickyfrog@mac.com',
    'tim.brinkley@gmail.com',
    'maniscalcojohn@gmail.com',
    'jonathansaffian@mac.com',
    'chrishayden@mac.com',
    'cliff.mcgaha@gmail.com',
    'doranwoods@gmail.com',
    'jlwelty@gmail.com',
    'ryanjnickell@gmail.com',
    'berrytexas93@gmail.com',
    'paul@studiostream.com',
    'troybilt@yahoo.com',
    'anotheraccountjso@gmail.com',
    'jeromey@gmail.com',
    'andrewseabury@gmail.com',
    'zane.c.keller@gmail.com',
    'brianko@gmail.com',
    'ardimus@gmail.com',
    'ahulett@live.com',
    'ejfreed@gmail.com',
    'kmcalpin18@yahoo.com',
    'kmr1@hotmail.com',
    'hughrscott@mac.com',
    'Craig.Albright@gmail.com',
    'john.allard@gmail.com',
    'takeshi@briareus.net',
    'olivier.guerreau@gmail.com',
    'Pathayes27@gmail.com',
    'jz+702@griddyx.com',
    'dev+002@griddyx.com',
    'dev-2048@griddyx.com',
    '1@mar17.com',
  ]
  return whiteList.includes(userEmail)
}
