import * as C from './constants'

export const showChangeAutoPayModal = () => ({
  type: C.SHOW_CHANGE_AUTO_PAY_MODAL,
})

export const hideChangeAutoPayModal = () => ({
  type: C.HIDE_CHANGE_AUTO_PAY_MODAL,
})

export const clearAccountError = () => ({
  type: C.CLEAR_ACCOUNT_ERROR,
})

export const updateBillingAutopayRequest = () => ({
  type: C.UPDATE_BILLING_AUTO_PAY_REQUEST,
})

export const updateAutoPayFailure = () => ({
  type: C.SUBMIT_UPDATE_AUTO_PAY_FAILURE,
})

export const updateAutoPaySuccess = payload => ({
  type: C.SUBMIT_UPDATE_AUTO_PAY_SUCCESS,
  payload,
})

export const submitUpdateAutoPay = payload => ({
  type: C.SUBMIT_UPDATE_AUTO_PAY,
  payload,
})

export const updateBillingStatementDeliveryRequest = () => ({
  type: C.UPDATE_BILLING_STATEMENT_DELIVERY_REQUEST,
})

export const updateBillingStatementDeliverySuccess = payload => ({
  type: C.SUBMIT_UPDATE_STATEMENT_DELIVERY_SUCCESS,
  payload,
})

export const updateBillingStatementDeliveryFailure = () => ({
  type: C.SUBMIT_UPDATE_STATEMENT_DELIVERY_FAILURE,
})

export const submitUpdateStatementDelivery = payload => ({
  type: C.SUBMIT_UPDATE_STATEMENT_DELIVERY,
  payload,
})

export const submitNewBillingAddressForm = (body, setters) => ({
  type: C.SUBMIT_NEW_BILLING_ADDRESS_FORM,
  body,
  setters,
})

export const submitNewEmailForm = (body, setters) => ({
  type: C.SUBMIT_NEW_EMAIL_FORM,
  body,
  setters,
})

export const submitNewPhoneForm = (body, setters) => ({
  type: C.SUBMIT_NEW_PHONE_FORM,
  body,
  setters,
})

export const submitLanguagePerference = (body, setters) => ({
  type: C.SUBMIT_LANGUAGE_PREFERENCE,
  body,
  setters,
})

export const submitRechargeForm = (body, setters) => ({
  type: C.SUBMIT_RECHARGE_FORM,
  body,
  setters,
})

export const submitNewPasswordForm = (body, setters) => ({
  type: C.SUBMIT_NEW_PASSWORD_FORM,
  body,
  setters,
})

export const submitAddCardForm = (token, isPrimary, setters) => ({
  type: C.SUBMIT_ADD_CARD_FORM,
  token,
  isPrimary,
  setters,
})

export const getCardsSuccess = payload => ({
  type: C.GET_CARDS_SUCCESS,
  payload,
})

export const deleteCard = (cardID, paymentMethodID, setters) => ({
  type: C.DELETE_CARD,
  cardID,
  paymentMethodID,
  setters,
})

export const deleteCardSuccess = payload => ({
  type: C.DELETE_CARD_SUCCESS,
  payload,
})

export const setPrimaryCard = (cardID, paymentMethodID, setters) => ({
  type: C.SET_PRIMARY_CARD,
  cardID,
  paymentMethodID,
  setters,
})

export const setPrimaryCardSuccess = payload => ({
  type: C.SET_PRIMARY_CARD_SUCCESS,
  payload,
})

export const getAccountRequest = () => ({
  type: C.GET_ACCOUNT_REQUEST,
})
export const getAccountSuccess = payload => ({
  type: C.GET_ACCOUNT_SUCCESS,
  payload,
})
export const getAccountFailure = error => ({
  type: C.GET_ACCOUNT_FAILURE,
  error,
})

export const updateUserRequest = () => ({
  type: C.UPDATE_USER_REQUEST,
})
export const updateUserSuccess = payload => ({
  type: C.UPDATE_USER_SUCCESS,
  payload,
})
export const updateUserFailure = error => ({
  type: C.UPDATE_USER_FAILURE,
  error,
})

export const addCardSuccess = payload => ({
  type: C.ADD_CARD_SUCCESS,
  payload,
})

export const addCardFailure = payload => ({
  type: C.ADD_CARD_FAILURE,
  payload,
})

export const submitAddFundsForm = (body, setters) => ({
  type: C.SUBMIT_ADD_FUNDS_FORM,
  body,
  setters,
})
export const addFundsSuccess = (payload, mixpanelProps) => ({
  type: C.ADD_FUNDS_SUCCESS,
  payload,
  mixpanelProps,
})
export const addFundsFailure = mixpanelProps => ({
  type: C.ADD_FUNDS_FAILURE,
  mixpanelProps,
})

export const showSubmitSuccessModal = payload => ({
  type: C.SHOW_SUBMIT_SUCCESS_MODAL,
  payload,
})
export const hideSubmitSuccessModal = () => ({
  type: C.HIDE_SUBMIT_SUCCESS_MODAL,
})
export const getDeposits = () => ({
  type: C.GET_DEPOSITS,
})
export const clearDeposits = () => ({
  type: C.CLEAR_DEPOSITS,
})
export const getDepositsRequest = () => ({
  type: C.GET_DEPOSITS_REQUEST,
})
export const getDepositsSuccess = payload => ({
  type: C.GET_DEPOSITS_SUCCESS,
  payload,
})
export const getDepositsFailure = payload => ({
  type: C.GET_DEPOSITS_FAILURE,
  payload,
})
export const hasMoreDeposits = () => ({
  type: C.HAS_MORE_DEPOSITS,
})
export const depositsPaginationComplete = () => ({
  type: C.DEPOSITS_PAGINATION_COMPLETE,
})
export const resetDeposits = () => ({
  type: C.RESET_DEPOSITS,
})

export const getProductsRequest = () => ({
  type: C.GET_PRODUCTS_REQUEST,
})
export const getProductsSuccess = payload => ({
  type: C.GET_PRODUCTS_SUCCESS,
  payload,
})
export const getProductsFailure = payload => ({
  type: C.GET_PRODUCTS_FAILTURE,
  payload,
})

export const submitChasePaymentCustomForm = (payload, setters) => ({
  type: C.SUBMIT_CHASE_PAYMENT_CUSTOM_FORM,
  payload,
  setters,
})

export const submitICheckPaymentCustomForm = (payload, setters) => ({
  type: C.SUBMIT_ICHECK_PAYMENT_CUSTOM_FORM,
  payload,
  setters,
})

export const submitChasePaymentCustomFormSuccess = payload => ({
  type: C.SUBMIT_CHASE_PAYMENT_CUSTOM_FORM_SUCCESS,
  payload,
})

export const submitChasePaymentCustomFormFailure = payload => ({
  type: C.SUBMIT_CHASE_PAYMENT_CUSTOM_FORM_FAILURE,
  payload,
})

// Previously used in App saga to execute getLoginData.
// Should be considered removed entirely where it used to be called
export const loadAccountPage = payload => ({
  type: C.LOAD_ACCOUNT_PAGE,
  payload,
})

export const getAccountBalanceFailure = payload => ({
  type: C.GET_ACCOUNT_BALANCE_FAILURE,
  payload,
})

export const getAccountBalanceSuccess = payload => ({
  type: C.GET_ACCOUNT_BALANCE_SUCCESS,
  payload,
})

export const getAccountStatementFailure = payload => ({
  type: C.GET_ACCOUNT_STATEMENT_FAILURE,
  payload,
})

export const getAccountStatementSuccess = payload => ({
  type: C.GET_ACCOUNT_STATEMENT_SUCCESS,
  payload,
})

export const clearNetMeteringHistory = () => ({
  type: C.CLEAR_NET_METERING_HISTORY,
})

export const getNetMeteringHistory = () => ({
  type: C.GET_NET_METERING_HISTORY,
})

export const getNetMeteringHistorySuccess = payload => ({
  type: C.GET_NET_METERING_HISTORY_SUCCESS,
  payload,
})

export const getNetMeteringHistoryFailure = payload => ({
  type: C.GET_NET_METERING_HISTORY_FAILURE,
  payload,
})