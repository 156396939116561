import { merge } from 'ramda'

import { GET_WEATHER_SUCCESS } from './constants'

export const initialState = {
  weather: {},
  currentPlan: {},
  refreshAt: null,
  loading: true,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_WEATHER_SUCCESS: {
      return merge(state, { weather: action.payload, loading: false })
    }

    default:
      return state
  }
}

export default reducer
