import * as C from './constants'

export const getNetMeterBalanceSuccess = payload => ({
  type: C.GET_NET_METER_BALANCE_SUCCESS,
  payload,
})

export const getNetMeterBalanceFailure = payload => ({
  type: C.GET_NET_METER_BALANCE_FAILURE,
  payload,
})

export const usagePageLoaded = payload => ({
  type: C.USAGE_PAGE_LOADED,
  payload,
})

export const usageMeterIDSelected = payload => ({
  type: C.USAGE_METERID_SELECTED,
  payload,
})

export const dateContextUpdateTriggered = payload => ({
  type: C.DATE_CONTEXT_UPDATE_TRIGGERED,
  payload,
})

export const dateContextUpdated = payload => ({
  type: C.DATE_CONTEXT_UPDATED,
  payload,
})

export const graphUpdateTriggered = payload => ({
  type: C.GRAPH_CONTEXT_UPDATED,
  payload,
})

export const searchUpdateTriggered = payload => ({
  type: C.SEARCH_UPDATE_TRIGGERED,
  payload,
})

export const searchContextUpdated = payload => ({
  type: C.SEARCH_CONTEXT_UPDATED,
  payload,
})

export const getUsageRequest = () => ({
  type: C.GET_USAGE_REQUEST,
})

export const getUsageSuccess = payload => ({
  type: C.GET_USAGE_SUCCESS,
  payload,
})

export const getUsageFailure = payload => ({
  type: C.GET_USAGE_FAILURE,
  payload,
})

export const usageDataInState = () => ({
  type: C.USAGE_DATA_IN_STATE,
})

export const drilldownToDate = payload => ({
  type: C.DRILLDOWN_DATE_SELECTED,
  payload,
})

export const showGraphBarSelection = payload => ({
  type: C.GRAPH_BAR_SELECTED,
  payload,
})

export const switchUsageMeter = () => ({
  type: C.SWITCH_USAGE_METER,
})

export const clearUsageData = () => ({
  type: C.CLEAR_USAGE_DATA,
})

export const setViewingGeneration = payload => ({
  type: C.SET_VIEWING_GENERATION,
  payload,
})

export const updateViewingGeneration = payload => ({
  type: C.UPDATE_VIEWING_GENERATION,
  payload,
})
