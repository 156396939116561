import * as C from './constants'

export const loadPromo = () => ({
  type: C.LOAD_PROMO,
})

export const updatePromo = promo => ({
  type: C.UPDATE_PROMO,
  payload: promo,
})

export const resetPromo = () => ({
  type: C.RESET_PROMO,
})
