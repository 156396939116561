import { i18nMark } from '@lingui/react'

export const SET_TIME_INTERVAL = 'usage/SET_TIME_INTERVAL'

export const GET_HISTORY_REQUEST = 'usage/GET_HISTORY_REQUEST'
export const GET_HISTORY_SUCCESS = 'usage/GET_HISTORY_SUCCESS'
export const GET_HISTORY_FAILURE = 'usage/GET_HISTORY_FAILURE'

export const INTERVALS = [
  { label: i18nMark('Hours'), value: 'hourly', weight: 0 },
  { label: i18nMark('Days'), value: 'daily', weight: 2 },
  { label: i18nMark('Months'), value: 'monthly', weight: 1 },
]
