import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'
import { Trans } from '@lingui/macro'
import { Text } from 'components/text'

import {
  RenewalSection,
  RenewalSectionDescription,
  RenewalDescription,
} from './styled'

import {
  selectLatestProduct,
  selectAccountNumber,
  selectBillingAddress,
} from 'pages/Account/selectors'
import { selectLocale } from 'modules/language/selectors'
import { selectRenewalOffer } from 'pages/Renewal/selectors'

import { companyInfo } from 'config/profile'
import { color } from 'styles/variables'

const RenewalBanner = ({
  billingAddress,
  accountNumber,
  renewal,
  latestProduct,
  locale,
  setBannerHeight,
}) => {
  const [zipcode, setZipcode] = useState('')
  const [renewalDate, setRenewalDate] = useState('')
  const [productEndDate, setProductEndDate] = useState('')
  const [visible, setVisible] = useState(false)

  const ref = useRef(null)
  useEffect(() => {
    const getDynamicBannerHeight = () => {
      if (ref.current && visible) {
        const style = getComputedStyle(ref.current)
        const height =
          ref.current.offsetHeight +
          parseInt(style.marginTop) +
          parseInt(style.marginBottom) +
          parseInt(style.paddingTop) +
          parseInt(style.paddingBottom)

        setBannerHeight(height)
      }
    }

    getDynamicBannerHeight()

    window.addEventListener('resize', getDynamicBannerHeight)

    return () => {
      window.removeEventListener('resize', getDynamicBannerHeight)
    }
  }, [ref, visible])

  useEffect(() => {
    if (billingAddress && renewal && latestProduct) {
      setZipcode(billingAddress.postal_code)
      setRenewalDate(renewal.enrollment.renewalDate)
      setProductEndDate(latestProduct.end_date)
    } else {
      setVisible(false)
    }
  }, [billingAddress, renewal, latestProduct])

  useEffect(() => {
    const providedDate = new Date(renewalDate)
    const now = new Date()
    const diffInMs = providedDate.getTime() - now.getTime()
    const diffInDays = Math.ceil(diffInMs / (1000 * 60 * 60 * 24))

    if (zipcode && renewalDate && diffInDays <= 90) {
      setVisible(true)
    } else {
      setVisible(false)
    }
  }, [zipcode, renewalDate])

  return (
    <div ref={ref}>
      {visible && (
        <RenewalSection>
          <RenewalSectionDescription>
            <RenewalDescription>
              <Trans>
                <Text width="100%" color={color.renewalBanner.text}>
                  Your contract is coming up for renewal. Current contract
                  expires:{' '}
                  {`${moment(productEndDate)
                    .locale(locale)
                    .format('MMMM D, YYYY')}.`}{' '}
                  <a
                    target="_self"
                    href={`${
                      companyInfo.renewalBaseUrl
                    }?zipCode=${zipcode.slice(
                      0,
                      5
                    )}&accountNumber=${accountNumber}`}
                    style={{ fontWeight: 'bold' }}
                  >
                    Click here
                  </a>{' '}
                  to review your renewal offers. Thank you for being a customer
                  of ours!
                </Text>
              </Trans>
            </RenewalDescription>
          </RenewalSectionDescription>
        </RenewalSection>
      )}
    </div>
  )
}

RenewalBanner.propTypes = {
  billingAddress: PropTypes.object,
  locale: PropTypes.string,
  latestProduct: PropTypes.object,
  accountNumber: PropTypes.string,
  renewal: PropTypes.object,
}

const mapStateToProps = createStructuredSelector({
  billingAddress: selectBillingAddress,
  locale: selectLocale,
  latestProduct: selectLatestProduct,
  accountNumber: selectAccountNumber,
  renewal: selectRenewalOffer,
})

export default connect(mapStateToProps)(RenewalBanner)
