import { merge, unionWith, eqBy, prop, pathOr } from 'ramda'
import { DateTime } from 'luxon'
import * as C from './constants'
import * as C_MEMBERS from 'modules/members/constants'
import { isMeterDisconnected } from '../../utils/usageUtils'

export const initialState = {
  meterID: '',
  timeZone: '',
  dateContext: 'monthly',
  searchDate: DateTime.local().toISO(), // change to use timezone
  currentDate: DateTime.local().toISO(), // change to use timezone
  graphContext: 'usage_kwh',
  isGuest: false,
  isLoading: false,
  data: [],
  netMeteringBankBalance: '',
  isViewingGeneration: false,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case C.CLEAR_USAGE_DATA: {
      return initialState
    }
    case C.GET_NET_METER_BALANCE_SUCCESS: {
      const netMeteringBankBalance = action.payload.amount
      return merge(state, { netMeteringBankBalance })
    }
    case C.GET_USAGE_SUCCESS: {
      const {
        payload: { data: actionData },
      } = action
      const { data: stateData } = state
      const unionTest = (a, b) =>
        eqBy(prop('aggregation'), a, b) && eqBy(prop('period'), a, b)
      const unionData = unionWith(unionTest, [actionData], stateData)
      return merge(state, { data: unionData })
    }
    case C.SWITCH_USAGE_METER: {
      return initialState
    }
    case C.USAGE_METERID_SELECTED: {
      let {
        meterID,
        timeZone,
        meterStatus,
        meterStartDate,
        meterEndDate,
        isGuest,
      } = action.payload
      const date = DateTime.local()
        .setZone(timeZone)
        .toISO()
      if (isGuest) {
        // defualt profile id for griddy guest meter
        meterID = meterID && meterID !== '0' ? meterID : 26
        meterStartDate = meterStartDate
          ? meterStartDate
          : new Date(
              new Date().setFullYear(new Date().getFullYear() - 1)
            ).toISOString()
      }
      const nextState = {
        meterID,
        timeZone,
        meterStatus,
        meterStartDate,
        meterEndDate,
        isGuest,
        searchDate: isMeterDisconnected(meterStatus) ? meterEndDate : date,
        currentDate: date,
      }
      return merge(state, nextState)
    }
    case C.GRAPH_BAR_SELECTED: {
      const nextState = {
        selectedPayload: action.payload,
      }
      return merge(state, nextState)
    }
    case C_MEMBERS.GET_METER_SUCCESS: {
      const nextState = {
        meterStatus: pathOr('', ['payload', 'status', 'status'], action),
        meterStartDate: pathOr('', ['payload', 'start_date'], action),
        meterEndDate: pathOr('', ['payload', 'end_date'], action),
      }
      return merge(state, nextState)
    }
    case C.GRAPH_CONTEXT_UPDATED:
    case C.SEARCH_CONTEXT_UPDATED:
    case C.USAGE_QUERY_PARAMS_LOADED:
    case C.DATE_CONTEXT_UPDATED: {
      return merge(state, action.payload)
    }
    case C.UPDATE_VIEWING_GENERATION: {
      return merge(initialState, { isViewingGeneration: action.payload })
    }
    default: {
      return state
    }
  }
}

export default reducer
