import { merge, concat } from 'ramda'

import {
  GET_ACCOUNT_REQUEST,
  GET_ACCOUNT_SUCCESS,
  DELETE_CARD_SUCCESS,
  SET_PRIMARY_CARD_SUCCESS,
  GET_CARDS_SUCCESS,
  ADD_FUNDS_SUCCESS,
  SHOW_SUBMIT_SUCCESS_MODAL,
  HIDE_SUBMIT_SUCCESS_MODAL,
  GET_DEPOSITS_SUCCESS,
  GET_DEPOSITS_REQUEST,
  CLEAR_DEPOSITS,
  HAS_MORE_DEPOSITS,
  DEPOSITS_PAGINATION_COMPLETE,
  GET_PRODUCTS_REQUEST,
  GET_PRODUCTS_SUCCESS,
  SUBMIT_CHASE_PAYMENT_CUSTOM_FORM_FAILURE,
  UPDATE_BILLING_AUTO_PAY_REQUEST,
  SUBMIT_UPDATE_AUTO_PAY_SUCCESS,
  SUBMIT_UPDATE_AUTO_PAY_FAILURE,
  UPDATE_BILLING_STATEMENT_DELIVERY_REQUEST,
  SUBMIT_UPDATE_STATEMENT_DELIVERY_SUCCESS,
  SUBMIT_UPDATE_STATEMENT_DELIVERY_FAILURE,
  CLEAR_ACCOUNT_ERROR,
  GET_ACCOUNT_BALANCE_SUCCESS,
  GET_ACCOUNT_BALANCE_FAILURE,
  GET_ACCOUNT_STATEMENT_SUCCESS,
  GET_ACCOUNT_STATEMENT_FAILURE,
  SHOW_CHANGE_AUTO_PAY_MODAL,
  HIDE_CHANGE_AUTO_PAY_MODAL,
  CLEAR_NET_METERING_HISTORY,
  GET_NET_METERING_HISTORY,
  GET_NET_METERING_HISTORY_SUCCESS,
  GET_NET_METERING_HISTORY_FAILURE,
} from './constants'

const initialState = {
  loading: true,
  cards: [],
  submitResult: null,
  error: '',
  balance: {
    current: null,
    pending: null,
    estTopOffDate: null,
  },
  canPaginateDeposits: false,
  paginationLoading: false,
  deposits: [],
  products: [],
  latestProduct: {},
  billingMethod: 'postpay',
  autopay: false,
  statement_delivery: true,
  accountBalance: {
    member_id: '',
    current_balance: '',
    balance_due: '',
    due_date: '',
    last_bill_amount: '',
    disable_ach_payments: false,
  },
  accountStatement: null,
  showAutoPayModal: false,
  netMeteringHistory: {
    list: [],
    has_next: true,
  }

}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case HIDE_CHANGE_AUTO_PAY_MODAL: {
      return merge(state, { showAutoPayModal: false })
    }
    case SHOW_CHANGE_AUTO_PAY_MODAL: {
      return merge(state, { showAutoPayModal: true })
    }
    case UPDATE_BILLING_AUTO_PAY_REQUEST:
    case UPDATE_BILLING_STATEMENT_DELIVERY_REQUEST:
    case GET_ACCOUNT_REQUEST: {
      return merge(state, { loading: true })
    }

    case CLEAR_DEPOSITS: {
      return merge(state, { deposits: [] })
    }
    case HAS_MORE_DEPOSITS: {
      return merge(state, { canPaginateDeposits: true })
    }
    case DEPOSITS_PAGINATION_COMPLETE: {
      return merge(state, { canPaginateDeposits: false })
    }
    case GET_DEPOSITS_REQUEST: {
      return merge(state, { paginationLoading: true })
    }
    case GET_DEPOSITS_SUCCESS: {
      const { deposits } = action.payload
      return merge(state, {
        deposits: concat(state.deposits, deposits),
        paginationLoading: false,
      })
    }
    case GET_PRODUCTS_REQUEST: {
      return merge(state, { paginationLoading: true })
    }
    case GET_PRODUCTS_SUCCESS: {
      const { products, latestProduct } = action.payload
      return merge(state, {
        products: products,
        latestProduct: latestProduct,
        paginationLoading: false,
      })
    }
    case ADD_FUNDS_SUCCESS:
    case GET_ACCOUNT_SUCCESS: {
      return merge(state, { ...action.payload, loading: false })
    }

    case GET_CARDS_SUCCESS: {
      const { cards } = action.payload
      return merge(state, { cards })
    }

    case DELETE_CARD_SUCCESS: {
      const { cardID } = action.payload
      return merge(state, {
        cards: state.cards.filter(card => card.CardID !== cardID),
      })
    }

    case SET_PRIMARY_CARD_SUCCESS: {
      const { cardID } = action.payload
      return merge(state, {
        cards: state.cards.map(card =>
          card.CardID === cardID
            ? { ...card, default: true }
            : { ...card, default: false }
        ),
      })
    }

    case SHOW_SUBMIT_SUCCESS_MODAL: {
      return merge(state, { submitResult: action.payload, error: '' })
    }
    case HIDE_SUBMIT_SUCCESS_MODAL: {
      return merge(state, { submitResult: null })
    }

    case SUBMIT_UPDATE_STATEMENT_DELIVERY_FAILURE:
    case SUBMIT_UPDATE_AUTO_PAY_FAILURE:
    case SUBMIT_CHASE_PAYMENT_CUSTOM_FORM_FAILURE:
    case GET_ACCOUNT_STATEMENT_FAILURE:
    case GET_ACCOUNT_BALANCE_FAILURE: {
      return merge(state, {
        error: 'Something went wrong, please try again later!',
      })
    }
    case SUBMIT_UPDATE_AUTO_PAY_SUCCESS: {
      return merge(state, {
        autopay: action.payload,
        loading: false,
      })
    }
    case SUBMIT_UPDATE_STATEMENT_DELIVERY_SUCCESS: {
      return merge(state, {
        statement_delivery: action.payload === 'email' ? true : false,
        loading: false,
      })
    }
    case CLEAR_ACCOUNT_ERROR: {
      return merge(state, { error: '' })
    }

    case GET_ACCOUNT_BALANCE_SUCCESS: {
      return merge(state, { accountBalance: action.payload })
    }

    case GET_ACCOUNT_STATEMENT_SUCCESS: {
      return merge(state, { accountStatement: action.payload.statements ? action.payload.statements[0] : null })
    }

    case CLEAR_NET_METERING_HISTORY: {
      return merge(state, { netMeteringHistory: { list: [], nas_next: false } })
    }

    case GET_NET_METERING_HISTORY: {
      return merge(state, { paginationLoading: true })
    }

    case GET_NET_METERING_HISTORY_SUCCESS: {
      const { list, has_next } = action.payload

      return merge(state, {
        netMeteringHistory: { list: concat(state.netMeteringHistory.list, list), has_next },
        paginationLoading: false
      })
    }

    case GET_NET_METERING_HISTORY_FAILURE: {
      return merge(state, { netMeteringHistory: { list: [], nas_next: false }, paginationLoading: false })
    }

    default:
      return state
  }
}

export default reducer
