/*eslint no-underscore-dangle: ["error", { "allow": ["_paq"] }]*/

export const iconMediaPageView = pathname => {
  if (!window._paq) {
    setTimeout(() => {
      iconMediaPageView(pathname)
    }, 5000)
  }
  if (window._paq) {
    window._paq.push(['trackPageView', pathname])
  }
}

export const iconMediaLead = pathname => {
  window.dataLayer = window.dataLayer || []
  switch (pathname) {
    case '/sign-up/service-address':
      return window.dataLayer.push(
        { 'service-address': 'Lead' },
        { platform: 'web' },
        { event: 'stg.pageView' }
      )
    case '/add-meter/service-address':
      return window.dataLayer.push(
        { 'add-service-address': 'Lead' },
        { 'add-platform': 'web' },
        { event: 'stg.pageView' }
      )
    default:
      return null
  }
}

export const iconMediaAcquisition = type => {
  window.dataLayer = window.dataLayer || []
  switch (type) {
    case 'signup/ENROLL_SUCCESS':
      return window.dataLayer.push(
        { 'thank-you': 'Acquisition' },
        { platform: 'web' },
        { event: 'stg.pageView' }
      )
    case 'addMeter/MM_ENROLL_SUCCESS':
      return window.dataLayer.push(
        { 'add-thank-you': 'Acquisition' },
        { 'add-platform': 'web' },
        { event: 'stg.pageView' }
      )
    default:
      return null
  }
}

export const iconMediaGuestAcquisition = () => {
  window.dataLayer = window.dataLayer || []
  return window.dataLayer.push(
    { 'guest-thank-you': 'Acquisition' },
    { 'guest-platform': 'web' },
    { event: 'stg.pageView' }
  )
}

export const setIconMediaUser = userId => {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({ userId })
}

export const iconMediaGuestUser = memberID => {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push(
    { userId: memberID },
    { 'guest-sign-up': 'Lead' },
    { 'guest-platform': 'web' },
    { event: 'stg.pageView' }
  )
}
