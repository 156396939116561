import * as API from 'api'

import { selectIsGriddyGuest } from 'modules/members/selectors'

const initialState = {
  loading: true,
  hide_savings: true,
  totalMembersSavings: {},
  monthly: [],
}
const savings = {
  name: 'savings',
  state: initialState,
  reducers: {
    getSavingsRequest() {
      return initialState
    },
    getSavingsSuccess(state, payload) {
      return {
        loading: false,
        ...payload,
      }
    },
    setSelectedIndex(state, index) {
      return {
        ...state,
        selectedIndex: index,
      }
    },
  },
  effects: dispatch => ({
    async getSavings({ memberID, resetState }, rootState) {
      if (resetState) {
        dispatch.savings.getSavingsRequest()
      }
      const isGriddyGuest = selectIsGriddyGuest(rootState)
      const apiEndpoint = isGriddyGuest ? API.getGuestSavings : API.getSavings
      const res = await apiEndpoint({ memberID })

      // pad same data on first and last index to fit graph design
      const paddedMonthly = res.monthly.reduce((acc, value, index) => {
        if (index === 0) {
          return [value, value]
        }
        if (index === res.monthly.length - 1) {
          return acc.concat([value, value])
        }
        return acc.concat([value])
      }, [])

      let totalMembersSavings = {}

      if (res.hide_savings) {
        totalMembersSavings = await API.getTotalSavings()
      }

      dispatch.savings.getSavingsSuccess({
        ...res,
        monthly: paddedMonthly,
        selectedIndex: res.monthly.length,
        totalMembersSavings,
      })
      return res
    },
  }),
  selectors: (slice, createSelector) => ({
    isLoading() {
      return slice(state => state.loading)
    },
    hideSavings() {
      return slice(state => state.hide_savings)
    },
    totalMembersSavings() {
      return slice(state => state.totalMembersSavings)
    },
    totals() {
      return slice(state => state.totals || {})
    },
    monthly() {
      return slice(state => state.monthly || [])
    },
    graphData() {
      return createSelector(this.monthly, monthly => {
        const formatLength = 'yyyy-mm'.length
        return monthly.map((data, index) => ({
          period:
            index === 0 || index === monthly.length - 1
              ? ''
              : data.period.substring(0, formatLength),
          griddy: Number(data.griddy_all_in_costs),
          eia: Number(data.eia_allin_costs),
        }))
      })
    },
    dataMinMaxRange() {
      return createSelector(this.graphData, graphData => {
        const { minValue, maxValue } = graphData.reduce(
          (acc, { griddy, eia }) => {
            acc.minValue = Math.min(acc.minValue, griddy, eia)
            acc.maxValue = Math.max(acc.maxValue, griddy, eia)
            return acc
          },
          { minValue: Number.MAX_VALUE, maxValue: Number.MIN_VALUE }
        )
        return maxValue + Math.abs(minValue)
      })
    },
    selectedIndex() {
      return slice(state => state.selectedIndex)
    },
    selectedMonthData() {
      return createSelector(this.monthly, slice, (monthly, state) =>
        monthly.length ? monthly[state.selectedIndex] : {}
      )
    },
  }),
}

export default savings
