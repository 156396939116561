import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Text } from 'components/text'
import { LinkButton } from 'components/buttons'
import { selectIsLangEnglish } from 'modules/language/selectors'
import { createStructuredSelector } from 'reselect'
import { selectRenewalOffer } from 'pages/Renewal/selectors'
import { selectLatestProduct } from 'pages/Account/selectors'

import {
  PlusSection,
  PlusSectionDescription,
  PlusDescription,
} from './styled'

import { color } from 'styles/variables'

const PlusBanner = ({
  setBannerHeight,
  isEnglish,
  renewal,
  latestProduct,
}) => {
  const [renewalDate, setRenewalDate] = useState('')
  const [productEndDate, setProductEndDate] = useState('')
  const [visible, setVisible] = useState(false)

  const ref = useRef(null)
  useEffect(() => {
    const getDynamicBannerHeight = () => {
      if (ref.current) {
        const style = getComputedStyle(ref.current)
        const height =
          ref.current.offsetHeight +
          parseInt(style.marginTop) +
          parseInt(style.marginBottom) +
          parseInt(style.paddingTop) +
          parseInt(style.paddingBottom)

        setBannerHeight(height)
      }
    }

    getDynamicBannerHeight()

    window.addEventListener('resize', getDynamicBannerHeight)

    return () => {
      window.removeEventListener('resize', getDynamicBannerHeight)
    }
  }, [ref, visible])

  useEffect(() => {
    if (renewal && latestProduct) {
      setRenewalDate(renewal.enrollment.renewalDate)
      setProductEndDate(latestProduct.end_date)
    } else {
      setVisible(true)
    }
  }, [renewal, latestProduct])

  useEffect(() => {
    const providedDate = new Date(renewalDate)
    const now = new Date()
    const diffInMs = providedDate.getTime() - now.getTime()
    const diffInDays = Math.ceil(diffInMs / (1000 * 60 * 60 * 24))

    if (diffInDays <= 90) {
      setVisible(false)
    } else {
      setVisible(true)
    }
  }, [renewalDate, productEndDate])

  return (
    <div ref={ref}>
      {
        visible &&
        color.plusBanner &&
        color.plusBanner.bannerTextEn &&
        color.plusBanner.bannerTextEs &&
        <PlusSection>
          <PlusSectionDescription>
            <PlusDescription>
              <Text width='100%' color='white'>
                {isEnglish ? color.plusBanner.bannerTextEn : color.plusBanner.bannerTextEs}{' '}
                <LinkButton href={color.plusBanner.bannerUrl} color='white'>
                  {isEnglish ? 'Click here' : 'Haz clic aquí'}
                </LinkButton>
              </Text>
            </PlusDescription>
          </PlusSectionDescription>
        </PlusSection>
      }
    </div>
  )
}

PlusBanner.propTypes = {
  isEnglish: PropTypes.bool.isRequired,
  latestProduct: PropTypes.object,
  renewal: PropTypes.object,
}

const mapStateToProps = createStructuredSelector({
  isEnglish: selectIsLangEnglish,
  latestProduct: selectLatestProduct,
  renewal: selectRenewalOffer,
})


export default connect(mapStateToProps)(PlusBanner)
