import { createSelector } from 'reselect'

const selectLanguage = state => state.language

const selectLocale = createSelector(
  selectLanguage,
  languageState => languageState.locale
)

const selectIsLangEnglish = createSelector(
  selectLanguage,
  languageState => languageState.locale === 'en'
)

export { selectLanguage, selectLocale, selectIsLangEnglish }
